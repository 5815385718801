import React, { useEffect, useState } from "react";
import createPlotlyComponent from "react-plotly.js/factory";
import { useSelector } from "react-redux";
import { get } from "lodash";
import Skeleton from "@material-ui/core/Skeleton";
import Notification from "../../../helpers/Notification";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import { useTheme } from "@material-ui/core/styles";

const Plotly = window.Plotly;
const Plot = createPlotlyComponent(Plotly);

const SunburstCard = ({ left: leftSide, sunburstData }) => {
  const theme = useTheme();
  const [errorOpen, setErrorOpen] = useState(false);

  const error = useSelector(({ resultsData: { sunburst } }) =>
    get(sunburst, leftSide ? "left.error" : "right.error", null)
  );
  const loading = useSelector(({ resultsData: { sunburst } }) =>
    get(sunburst, leftSide ? "left.loading" : "right.loading", false)
  );

  const onErrorNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  useEffect(() => {
    if (error) {
      setErrorOpen(true);
    }
  }, [error]);

  return (
    <div>
      {error && (
        <Notification
          severity={"error"}
          onClose={onErrorNotificationClose}
          message={error}
          open={errorOpen}
          autoHideDuration={60000}
        />
      )}
      {loading || !Boolean(sunburstData) ? (
        <Skeleton variant="rectangular" height={550} animation="wave" />
      ) : (
        <Card elevation={0} sx={{ borderRadius: "0 !important" }}>
          <CardHeader
            title={
              <Typography
                variant="h6"
                sx={{ display: "flex", alignItems: "center" }}
              >
                Vulnerability Distribution - Commit {leftSide ? "1" : "2"}
              </Typography>
            }
            sx={{ padding: "16px 24px 16px 24px" }}
          />
          <Divider />
          <CardContent sx={{ padding: "0 !important" }}>
            <div
              style={{
                margin: "20px 20px",
                marginBottom: "20px",
                padding: "0 50px",
                textAlign: "center",
                overflowX: "auto",
              }}
            >
              <Plot
                data={[
                  {
                    type: "sunburst",
                    labels: sunburstData?.labels || null,
                    parents: sunburstData?.parents || null,
                    values: sunburstData?.values || null,
                    outsidetextfont: { size: 20, color: "#377eb8" },
                    leaf: { opacity: 1 },
                    showlegend: false,
                    marker: {
                      line: { width: 2 },
                      autocolorscale: true,
                      colorscale: "YlOrRd",
                      showscale: true,
                      colorbar: {
                        tickcolor: "white",
                        tickfont: {
                          color: "white",
                        },
                      },
                    },
                  },
                ]}
                layout={{
                  margin: { l: 0, r: 0, b: 0, t: 0 },
                  width: "100%",
                  height: "100%",
                  paper_bgcolor: theme.palette.background.paper,
                  plot_bgcolor: "transparent",
                  showlegend: true,
                }}
              />
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default SunburstCard;
