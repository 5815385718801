import * as actions from "../../../actions/actionTypes";

const initialState = {
  left: null,
  right: null,
};

const commitReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.setLeftCommit.SUCCESS:
      return {
        ...state,
        left: payload,
      };

    case actions.setRightCommit.SUCCESS:
      return {
        ...state,
        right: payload,
      };

    case actions.setLeftCommit.FULFILL:
      return initialState;

    default:
      return state;
  }
};

export default commitReducer;
