export const hhmmssToSecs = (str) => {
  const [hh = "0", mm = "0", ss = "0"] = (str || "0:0:0").split(":");
  const hour = parseInt(hh, 10) || 0;
  const minute = parseInt(mm, 10) || 0;
  const second = parseInt(ss, 10) || 0;
  return hour * 3600 + minute * 60 + second;
};

export const getEstimatedTime = (analyzedCount, totalCount, analysisTime) =>
  (totalCount - analyzedCount) * hhmmssToSecs(analysisTime) * 1000 +
  new Date().getTime();
