import * as actions from "../../../actions/actionTypes";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const rightReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.getRightVulnerabilities.TRIGGER:
      return {
        ...state,
        loading: true,
      };

    case actions.getRightVulnerabilities.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: payload,
      };

    case actions.getRightVulnerabilities.FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case actions.getRightVulnerabilities.FULFILL:
      return initialState;

    default:
      return state;
  }
};

export default rightReducer;
