import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import { useParams } from "react-router-dom";
import Skeleton from "@material-ui/core/Skeleton";
import Notification from "../../../../helpers/Notification";
import TimelineGraph from "./TimelineGraph";
import { connect, useDispatch, useSelector } from "react-redux";
import { createSelectorFactory } from "../../../../utils/createSelectorFactory";
import {
  clearTimelineScoresData,
  getTimelineScoresData,
} from "../../../../store/actions";
import { useFirebase } from "react-redux-firebase";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: `${theme.palette.background.default} !important`,
  },
  typography: {
    marginBottom: "15px",
  },
}));

const Timeline = ({ open, onClose, timelineData }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const [errorOpen, setErrorOpen] = useState(false);

  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { owner: repoOwner, repo: repoName, language } = useParams();

  const error = useSelector(
    ({
      resultsData: {
        timeline: { error },
      },
    }) => error
  );
  const loading = useSelector(
    ({
      resultsData: {
        timeline: { loading },
      },
    }) => loading
  );

  useEffect(() => {
    getTimelineScoresData(repoOwner, repoName, language)(dispatch, firebase);
  }, [repoOwner, repoName, language, dispatch, firebase]);

  useEffect(() => () => clearTimelineScoresData()(dispatch), [dispatch]);

  useEffect(() => setErrorOpen(Boolean(error)), [error]);

  const onErrorNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  return (
    <div>
      {error && (
        <Notification
          severity={"error"}
          onClose={onErrorNotificationClose}
          message={error}
          open={errorOpen}
          autoHideDuration={60000}
        />
      )}
      <Dialog
        keepMounted={false}
        scroll="body"
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography variant="body1" component="div">
            Vulnerability Score Timeline
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {Boolean(timelineData) && !loading ? (
            <TimelineGraph timelineData={timelineData} />
          ) : (
            <div>
              <Typography variant="body2" sx={{ mb: 2 }}>
                We are crunching your data, this may take a moment!
              </Typography>
              <Skeleton variant="rectangular" height={400} animation="wave" />
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default connect((state) => ({
  timelineData: createSelectorFactory(`resultsData.timeline.data`)(state),
}))(Timeline);
