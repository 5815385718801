import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  typography: {
    flexGrow: 1,
    textAlign: "center",
    color: theme.palette.text.primary,
  },
  appBar: {
    height: "64px",
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar
        position={"static"}
        className={classes.appBar}
        color="default"
        sx={{ boxShadow: 25 }}
      >
        <Container maxWidth="lg">
          <Toolbar>
            <Typography variant="body2" className={classes.typography}>
              Sequza &#169; {new Date().getFullYear()}
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default Footer;
