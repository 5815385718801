import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { get } from "lodash";
import Notification from "../../../helpers/Notification";
import Skeleton from "@material-ui/core/Skeleton";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import { DataGrid } from "@material-ui/data-grid";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CustomNoRowsOverlay from "../../../helpers/CustomNoRowsOverlay";
import CustomLoadingOverlay from "../../../helpers/CustomLoadingOverlay";
import FileDetails from "./FileDetails";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const viewDetailsButton = () => {
  return (
    <Button variant="text" color="inherit">
      View
    </Button>
  );
};

const columns = [
  {
    field: "path",
    headerName: "Path",
    description: "Vulnerable file path",
    flex: 3,
  },
  {
    field: "details",
    headerName: "Details",
    description: "Details on the vulnerabilities detected",
    renderCell: viewDetailsButton,
    flex: 1,
  },
  {
    field: "total",
    headerName: "Total",
    description: "Total score for the vulnerable file path",
    flex: 1,
  },
  {
    field: "severity",
    headerName: "Severity",
    description: "Severity score for the vulnerable file path",
    flex: 1,
  },
  {
    field: "sibling",
    headerName: "Sibling",
    description: "Sibling score for the vulnerable file path",
    flex: 1,
  },
  {
    field: "introFix",
    headerName: "Intro+Fix",
    description: "Intro+Fix score for the vulnerable file path",
    flex: 1,
  },
  {
    field: "lifetime",
    headerName: "Lifetime",
    description: "Lifetime score for the vulnerable file path",
    flex: 1,
  },
];

const ScoreComparator = ({ leftScore, rightScore }) => {
  if (scoreComparator(leftScore, rightScore) > 0) {
    return <ArrowUpwardIcon sx={{ color: "red" }} />;
  } else if (scoreComparator(leftScore, rightScore) < 0) {
    return <ArrowDownwardIcon sx={{ color: "green" }} />;
  } else {
    return null;
  }
};
const scoreComparator = (leftScore, rightScore) =>
  (rightScore?.overallScore || 0) - (leftScore?.overallScore || 0);

const getScoreColor = (leftSide, leftScore, rightScore) => {
  if (leftSide) {
    return "inherit";
  }
  if (scoreComparator(leftScore, rightScore) > 0) {
    return "red";
  } else if (scoreComparator(leftScore, rightScore) < 0) {
    return "green";
  } else {
    return "inherit";
  }
};

/**
 * @param {boolean} leftSide
 * @param {object} scoresData
 * @param {object} leftScores
 * @param {number} scoresData.overallScore
 * @param {array<object>} scoresData.scores
 * @param {number} scoresData.totalCount
 * @returns {JSX.Element}
 * @constructor
 */
const ScoreCard = ({ left: leftSide, scoresData, leftScores }) => {
  const [errorOpen, setErrorOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [path, setPath] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPerPage] = useState(5);

  const error = useSelector(({ resultsData: { scores } }) =>
    get(scores, leftSide ? "left.error" : "right.error", null)
  );
  const loading = useSelector(({ resultsData: { scores } }) =>
    get(scores, leftSide ? "left.loading" : "right.loading", false)
  );

  const onErrorNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  useEffect(() => {
    if (error) {
      setErrorOpen(true);
    }
  }, [error]);

  const handleViewClick = ({ field, id, getValue }) => {
    if (field !== "details") {
      return;
    }
    setVisible(true);
    setPath(getValue(id, "path"));
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePageSizeChange = (pageSize) => {
    setPerPage(pageSize);
  };

  return (
    <div>
      {error && (
        <Notification
          severity={"error"}
          onClose={onErrorNotificationClose}
          message={error}
          open={errorOpen}
          autoHideDuration={60000}
        />
      )}

      {loading || !Boolean(scoresData) ? (
        <Skeleton variant="rectangular" height={620} animation="wave" />
      ) : (
        <Card elevation={0} sx={{ borderRadius: "0 !important" }}>
          <CardHeader
            title={
              <Typography
                variant="h6"
                sx={{ display: "flex", alignItems: "center" }}
              >
                Score Breakdown - Commit {leftSide ? "1" : "2"}
              </Typography>
            }
            sx={{ padding: "16px 24px 16px 24px" }}
          />
          <Divider />
          <CardContent
            sx={{
              padding: "16px",
            }}
          >
            <Box component="div" sx={{ my: 2 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Overall Score
              </Typography>
              <Typography
                variant="h5"
                sx={{ color: getScoreColor(leftSide, leftScores, scoresData) }}
              >
                {!Boolean(leftSide) && Boolean(leftScores) && (
                  <ScoreComparator
                    leftScore={leftScores}
                    rightScore={scoresData}
                  />
                )}{" "}
                {scoresData?.overallScore.toFixed(3)}
              </Typography>
            </Box>
            <Box component="div" sx={{ overflow: "auto" }}>
              <Paper sx={{ display: "flex", width: "1500px" }}>
                {visible && (
                  <FileDetails
                    left={leftSide}
                    open={visible}
                    path={path}
                    onClose={() => setVisible(false)}
                  />
                )}
                <DataGrid
                  rows={scoresData.scores}
                  columns={columns}
                  page={page}
                  pageSize={pageSize}
                  rowsPerPageOptions={[1, 5, 10, 20]}
                  loading={loading}
                  rowCount={scoresData.totalCount}
                  paginationMode="client"
                  onPageChange={handlePageChange}
                  onPageSizeChange={handlePageSizeChange}
                  autoHeight
                  pagination
                  disableSelectionOnClick
                  onCellClick={handleViewClick}
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                    NoResultsOverlay: CustomNoRowsOverlay,
                    LoadingOverlay: CustomLoadingOverlay,
                  }}
                />
              </Paper>
            </Box>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default ScoreCard;
