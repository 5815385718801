import React from "react";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import { connect } from "react-redux";
import { createSelectorFactory } from "../../../utils/createSelectorFactory";

const CommitCardHeader = ({
  left,
  detailsLoading,
  sunburstLoading,
  scoreLoading,
}) => {
  return (
    <div
      style={{
        fontSize: "17px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <div>
        <Typography variant="h6" sx={{ display: "flex", alignItems: "center" }}>
          {left ? "Commit 1" : "Commit 2"}
        </Typography>
      </div>
      {(detailsLoading || sunburstLoading || scoreLoading) && (
        <Box
          sx={{
            display: "flex",
            marginLeft: "auto",
            marginRight: 0,
          }}
        >
          <CircularProgress color="inherit" size={25} />
        </Box>
      )}
    </div>
  );
};

export default connect((state, { left }) => ({
  detailsLoading: createSelectorFactory(
    `resultsData.details.${left ? "left" : "right"}.loading`
  )(state),
  sunburstLoading: createSelectorFactory(
    `resultsData.sunburst.${left ? "left" : "right"}.loading`
  )(state),
  scoreLoading: createSelectorFactory(
    `resultsData.scores.${left ? "left" : "right"}.loading`
  )(state),
}))(CommitCardHeader);
