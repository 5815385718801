import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: `${theme.palette.background.default} !important`,
  },
  typography: {
    marginBottom: "15px",
  },
}));

const Legend = ({ open, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={"md"}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography variant="h6" component="div">
          Terminology
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <div className={classes.typography}>
          <Typography variant="h6" style={{ marginBottom: "4px" }}>
            Total Intro Commits
          </Typography>
          <Typography variant="body2">
            Total number of unique commits which introduce vulnerabilities into
            the codebase
          </Typography>
        </div>
        <div className={classes.typography}>
          <Typography variant="h6" style={{ marginBottom: "4px" }}>
            Intro Only Commits
          </Typography>
          <Typography variant="body2">
            Intro commits where there is one or more vulnerability without a fix
          </Typography>
        </div>
        <div className={classes.typography}>
          <Typography variant="h6" style={{ marginBottom: "4px" }}>
            Total Fix Commits
          </Typography>
          <Typography variant="body2">
            Total number of commits where one or more vulnerability has been
            fixed
          </Typography>
        </div>
        <div className={classes.typography}>
          <Typography variant="h6" style={{ marginBottom: "4px" }}>
            Fix Only Commits
          </Typography>
          <Typography variant="body2">
            Commits where no vulnerability is introduced upon fixing a
            vulnerability
          </Typography>
        </div>
        <div className={classes.typography}>
          <Typography variant="h6" style={{ marginBottom: "4px" }}>
            Intro + Fix Commits
          </Typography>
          <Typography variant="body2">
            Commits which are responsible for fixing existing vulnerabilities in
            the codebase while introducing one or more new vulnerabilities into
            the codebase as well
          </Typography>
        </div>
        <div className={classes.typography}>
          <Typography variant="h6" style={{ marginBottom: "4px" }}>
            Total Commits
          </Typography>
          <Typography variant="body2">
            Total number of commits analyzed using Sequza
          </Typography>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default Legend;
