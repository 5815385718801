import React from "react";
import createPlotlyComponent from "react-plotly.js/factory";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const Plotly = window.Plotly;
const Plot = createPlotlyComponent(Plotly);

const TimelineGraph = ({ timelineData }) => {
  const { dates, scores, commits, dateRange, scoreRange } = timelineData;
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div style={{ textAlign: "center", overflowX: "auto" }}>
      <Plot
        data={[
          {
            x: dates,
            y: scores,
            text: commits,
            type: "scatter",
            mode: "lines+markers",
            name: "Scores",
            line: { color: "#34B7F1" },
            hovertemplate:
              "<b>Score: %{y:.3f}</b><br><b>Commit: %{text}</b><br>",
            hoverlabel: {
              namelength: 0,
              bgcolor: theme.palette.background.paper,
            },
          },
        ]}
        layout={{
          legend: {
            bgcolor: theme.palette.table,
            bordercolor: "rgba(189,189,189,0.31)",
            borderwidth: 1,
          },
          font: { color: theme.palette.text.primary },
          margin: { t: 60, b: 30 },
          autosize: false,
          width: smUp ? 1000 : "100%",
          paper_bgcolor: theme.palette.table,
          plot_bgcolor: "transparent",
          showlegend: true,
          xaxis: {
            zerolinecolor: "rgba(189,189,189,0.31)",
            gridcolor: "rgba(189,189,189,0.31)",
            automargin: true,
            autorange: true,
            range: dateRange,
            rangeselector: {
              bgcolor: theme.palette.background.default,
              margin: { b: 10 },
              buttons: [
                {
                  count: 1,
                  label: "1m",
                  step: "month",
                  stepmode: "backward",
                },
                {
                  count: 6,
                  label: "6m",
                  step: "month",
                  stepmode: "backward",
                },
                { step: "all" },
              ],
            },
            rangeslider: {
              range: dateRange,
              bordercolor: "rgba(189,189,189,0.31)",
              borderwidth: 1,
            },
            type: "date",
          },
          yaxis: {
            zerolinecolor: "rgba(189,189,189,0.31)",
            linecolor: "rgba(189,189,189,0.31)",
            gridcolor: "rgba(189,189,189,0.31)",
            autorange: true,
            range: scoreRange,
            type: "linear",
            automargin: true,
          },
        }}
      />
    </div>
  );
};

export default TimelineGraph;
