import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { styled, useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Collapse from "@material-ui/core/Collapse";
import ResultsHeader from "./ResultsHeader";
import { connect, useDispatch } from "react-redux";
import {
  clearCommitDetails,
  clearCommitShaList,
  clearPositions,
  clearResultsHeadData,
  clearScores,
  clearSunburstData,
  getCommitDetails,
  getCommitShaList,
  getResultsHead,
  getSunburstData,
} from "../../store/actions";
import { useParams } from "react-router-dom";
import CommitCard from "./CommitCard";
import CommitDetailsCard from "./CommitDetailsCard";
import SunburstCard from "./SunburstCard";
import { createSelectorFactory } from "../../utils/createSelectorFactory";
import ScoreCard from "./ScoreCard";
import { useFirebase } from "react-redux-firebase";
import NotificationViewer from "../../helpers/NotificationViewer";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  backgroundColor: expand ? "inherit" : theme.palette.tooltip,
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.enteringScreen,
  }),
}));

const useStyles = makeStyles(() => ({
  container: {
    flexGrow: 1,
    display: "flex",
  },
}));

const Results = ({
  resultsHeadData,
  commitList,
  leftCommitDetails,
  rightCommitDetails,
  leftCommit,
  rightCommit,
  leftSunburstData,
  rightSunburstData,
  leftScores,
  rightScores,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const [expanded, setExpanded] = useState(true);
  const { owner: repoOwner, repo: repoName, language } = useParams();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    getResultsHead(repoOwner, repoName, language)(dispatch, firebase);
    getCommitShaList(repoOwner, repoName)(dispatch, firebase);
    return () => {
      clearResultsHeadData()(dispatch);
      clearCommitShaList()(dispatch);
    };
  }, [repoOwner, repoName, dispatch, firebase, language]);

  useEffect(() => {
    if (leftCommit) {
      getCommitDetails(
        repoOwner,
        repoName,
        leftCommit,
        language,
        true
      )(dispatch, firebase);
      getSunburstData(
        repoOwner,
        repoName,
        leftCommit,
        language,
        true
      )(dispatch, firebase);
    } else {
      clearCommitDetails(true)(dispatch);
      clearSunburstData(true)(dispatch);
      clearScores(true)(dispatch);
    }
  }, [repoOwner, repoName, dispatch, leftCommit, firebase, language]);

  useEffect(() => {
    if (rightCommit) {
      getCommitDetails(
        repoOwner,
        repoName,
        rightCommit,
        language,
        false
      )(dispatch, firebase);
      getSunburstData(
        repoOwner,
        repoName,
        rightCommit,
        language,
        false
      )(dispatch, firebase);
    } else {
      clearCommitDetails(false)(dispatch);
      clearSunburstData(false)(dispatch);
      clearScores(false)(dispatch);
    }
  }, [repoOwner, repoName, dispatch, rightCommit, firebase, language]);

  useEffect(
    () => () => {
      clearCommitDetails(true)(dispatch);
      clearSunburstData(true)(dispatch);
      clearScores(true)(dispatch);
      clearPositions(true)(dispatch);
      clearCommitDetails(false)(dispatch);
      clearSunburstData(false)(dispatch);
      clearScores(false)(dispatch);
      clearPositions(false)(dispatch);
    },
    [dispatch]
  );

  return (
    <div className="container">
      <NotificationViewer />
      <Grid
        container
        className={classes.container}
        justifyContent="flex-end"
        alignItems="flex-start"
      >
        <Grid item sx={{ minWidth: "100%" }}>
          <Card
            elevation={0}
            sx={{
              backgroundColor: expanded
                ? theme.palette.primary.main
                : "transparent",
              borderRadius: "0",
            }}
          >
            <CardActions disableSpacing>
              <ExpandMore
                size="small"
                expand={expanded}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent sx={{ paddingTop: 0 }}>
                <ResultsHeader resultsHeadData={resultsHeadData} />
              </CardContent>
            </Collapse>
          </Card>
        </Grid>
      </Grid>
      <br />
      <Grid
        container
        spacing={2}
        className={classes.container}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid item lg={6} xs={12} md={6} xl={6}>
          <CommitCard left commitList={commitList} />
        </Grid>
        <Grid item lg={6} xs={12} md={6} xl={6}>
          <CommitCard commitList={commitList} />
        </Grid>
      </Grid>
      <br />
      {(leftCommitDetails || rightCommitDetails) && (
        <Grid
          container
          spacing={2}
          className={classes.container}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item lg={6} xs={12} md={6} xl={6}>
            {leftCommitDetails && (
              <CommitDetailsCard commitDetails={leftCommitDetails} left />
            )}
          </Grid>

          <Grid item lg={6} xs={12} md={6} xl={6}>
            {rightCommitDetails && (
              <CommitDetailsCard commitDetails={rightCommitDetails} />
            )}
          </Grid>
        </Grid>
      )}
      <br />
      {(leftSunburstData || rightSunburstData) && (
        <Grid
          container
          spacing={2}
          className={classes.container}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item lg={6} xs={12} md={6} xl={6}>
            {leftSunburstData && (
              <SunburstCard sunburstData={leftSunburstData} left />
            )}
          </Grid>

          <Grid item lg={6} xs={12} md={6} xl={6}>
            {rightSunburstData && (
              <SunburstCard sunburstData={rightSunburstData} />
            )}
          </Grid>
        </Grid>
      )}
      <br />
      {(leftScores || rightScores) && (
        <Grid
          container
          spacing={2}
          className={classes.container}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item lg={6} xs={12} md={6} xl={6}>
            {leftScores && <ScoreCard scoresData={leftScores} left />}
          </Grid>

          <Grid item lg={6} xs={12} md={6} xl={6}>
            {rightScores && (
              <ScoreCard scoresData={rightScores} leftScores={leftScores} />
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default connect((state) => ({
  resultsHeadData: createSelectorFactory("resultsData.headData.data")(state),
  commitList: createSelectorFactory("resultsData.commits.list.data")(state),
  leftCommitDetails: createSelectorFactory("resultsData.details.left.data")(
    state
  ),
  rightCommitDetails: createSelectorFactory("resultsData.details.right.data")(
    state
  ),
  leftCommit: createSelectorFactory("resultsData.commits.positions.left")(
    state
  ),
  rightCommit: createSelectorFactory("resultsData.commits.positions.right")(
    state
  ),
  leftSunburstData: createSelectorFactory("resultsData.sunburst.left.data")(
    state
  ),
  rightSunburstData: createSelectorFactory("resultsData.sunburst.right.data")(
    state
  ),
  leftScores: createSelectorFactory("resultsData.scores.left.data")(state),
  rightScores: createSelectorFactory("resultsData.scores.right.data")(state),
}))(Results);
