import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCodeBranch } from "@fortawesome/free-solid-svg-icons/faCodeBranch";
import Divider from "@material-ui/core/Divider";
import CustomizedToolTip from "../../../helpers/CustomizedToolTip";
import Paper from "@material-ui/core/Paper";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import colors from "../../../utils/colors.json";
import { get } from "lodash";
import Notification from "../../../helpers/Notification";
import { useSelector } from "react-redux";
import Skeleton from "@material-ui/core/Skeleton";
import { useHistory, useParams } from "react-router-dom";
import { getLanguage } from "../../../utils/getLanguage";
import { faChartLine } from "@fortawesome/free-solid-svg-icons/faChartLine";
import Timeline from "./Timeline";

const typographyBody = {
  marginLeft: "5px",
  marginBottom: "3px",
  marginRight: "5px",
};

const useStyles = makeStyles(() => ({
  button: {
    "&:hover": {
      border: "1px solid rgba(58,180,255,0.68)",
      color: "rgba(58,180,255,0.68)",
    },
  },
}));

/**
 *
 * @param resultsHeadData
 * @param resultsHeadData.logoURL
 * @param resultsHeadData.repoName
 * @param resultsHeadData.repoOwner
 * @param resultsHeadData.languages
 * @param resultsHeadData.url
 * @param resultsHeadData.description
 * @param resultsHeadData.introFix
 * @param resultsHeadData.allIntro
 * @param resultsHeadData.introOnly
 * @param resultsHeadData.allFix
 * @param resultsHeadData.current
 * @returns {JSX.Element}
 * @constructor
 */
const ResultsHeader = ({ resultsHeadData }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const [errorOpen, setErrorOpen] = useState(false);
  const [openTimeline, setOpenTimeline] = useState(false);

  const {
    owner: repoOwner,
    repo: repoName,
    language: urlLanguage,
  } = useParams();

  const error = useSelector(
    ({
      resultsData: {
        headData: { error },
      },
    }) => error
  );
  const loading = useSelector(
    ({
      resultsData: {
        headData: { loading },
      },
    }) => loading
  );

  const onErrorNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  useEffect(() => {
    if (error) {
      setErrorOpen(true);
    }
  }, [error]);

  const handleLanguageChange = (language) => {
    history.push(`/repo/${repoOwner}/${repoName}/${getLanguage(language)}`);
  };

  const handleViewTimeline = () => setOpenTimeline(true);

  return (
    <div>
      {error && (
        <Notification
          severity={"error"}
          onClose={onErrorNotificationClose}
          message={error}
          open={errorOpen}
          autoHideDuration={60000}
        />
      )}
      {loading || !Boolean(resultsHeadData) ? (
        <Skeleton
          variant="rectangular"
          height={215}
          animation="wave"
          sx={{
            borderRadius: "4px",
          }}
        />
      ) : (
        <Paper sx={{ padding: theme.spacing(2) }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar
                variant="circular"
                src={resultsHeadData?.logoURL}
                sx={{ width: 40, height: 40, marginRight: "10px" }}
              />
              <Typography variant="h5">{resultsHeadData?.repoName}</Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ marginLeft: "10px" }}
              >
                {resultsHeadData?.repoOwner}
              </Typography>
              {resultsHeadData?.languages.map((language, index) => (
                <Chip
                  key={index}
                  onClick={() => handleLanguageChange(language)}
                  clickable
                  label={language}
                  color="primary"
                  variant="outlined"
                  size="small"
                  sx={{
                    color: get(
                      colors,
                      `${language.toLowerCase()}.color`,
                      "inherit"
                    ),
                    backgroundColor:
                      urlLanguage === getLanguage(language)
                        ? "#222324"
                        : "transparent",
                    borderColor: get(
                      colors,
                      `${language.toLowerCase()}.color`,
                      "inherit"
                    ),
                    borderRadius: "1px",
                    padding: "5px 5px 5px 5px",
                    marginLeft: "10px",
                    fontSize: "12px",
                  }}
                />
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: mdDown ? "row" : "row-reverse",
                gap: "10px",
              }}
            >
              <Button
                color="inherit"
                variant="outlined"
                href={resultsHeadData?.url}
                target="_blank"
                rel="noreferrer noopener"
                startIcon={<FontAwesomeIcon icon={faCodeBranch} />}
                className={classes.button}
              >
                View Repository
              </Button>
              <Button
                color="inherit"
                variant="outlined"
                onClick={handleViewTimeline}
                startIcon={<FontAwesomeIcon icon={faChartLine} />}
                className={classes.button}
              >
                View Timeline
              </Button>
            </Grid>
            {openTimeline && (
              <Timeline
                open={openTimeline}
                onClose={() => setOpenTimeline(false)}
              />
            )}
            <Grid item xs={12} md={12}>
              <Typography variant="body2" sx={{ mb: 3 }}>
                {resultsHeadData?.description}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={2} sx={{ marginTop: theme.spacing(1) }}>
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              xl={4}
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="h6">{resultsHeadData?.introFix}</Typography>
              <CustomizedToolTip
                nomaxwidth="true"
                arrow
                placement="right-start"
                title="Commits which are responsible for fixing existing vulnerabilities in the codebase while introducing one or more new vulnerabilities into the codebase as well"
              >
                <Typography variant="body2" sx={typographyBody}>
                  Intro+Fix commits
                </Typography>
              </CustomizedToolTip>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              xl={4}
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="h6">{resultsHeadData?.allIntro}</Typography>
              <CustomizedToolTip
                nomaxwidth="true"
                arrow
                placement="top"
                title="Total number of unique commits which introduce vulnerabilities into the codebase"
              >
                <Typography variant="body2" sx={typographyBody}>
                  Intro commits
                </Typography>
              </CustomizedToolTip>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              xl={4}
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="h6">{resultsHeadData?.introOnly}</Typography>
              <CustomizedToolTip
                nomaxwidth="true"
                arrow
                placement="top"
                title="Intro commits where there is one or more vulnerability without a fix"
              >
                <Typography variant="body2" sx={typographyBody}>
                  Unfixed commits
                </Typography>
              </CustomizedToolTip>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              xl={4}
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="h6">{resultsHeadData?.allFix}</Typography>
              <CustomizedToolTip
                nomaxwidth="true"
                arrow
                placement="right-start"
                title="Total number of commits where one or more vulnerability has been fixed"
              >
                <Typography variant="body2" sx={typographyBody}>
                  Fix commits
                </Typography>
              </CustomizedToolTip>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={6}
              xl={6}
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <CustomizedToolTip
                nomaxwidth="true"
                arrow
                placement="top"
                title="Last commit analyzed with Sequza"
              >
                <Typography variant="body2" sx={typographyBody}>
                  Last analyzed commit: {resultsHeadData?.current}
                </Typography>
              </CustomizedToolTip>
            </Grid>
          </Grid>
        </Paper>
      )}
    </div>
  );
};

export default ResultsHeader;
