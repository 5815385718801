import React, { useEffect, useState } from "react";
import Notification from "./Notification";
import Avatar from "@material-ui/core/Avatar";
import { messaging } from "../config";

const NotificationViewer = () => {
  const [notificationPayload, setNotificationPayload] = useState(null);
  const [viewNotification, setViewNotification] = useState(false);

  useEffect(() => {
    if (messaging) {
      const unsubscribe = messaging.onMessage(
        (payload) => {
          setNotificationPayload(payload);
          setViewNotification(true);
        },
        (error) => console.log(error)
      );

      return () => {
        unsubscribe?.();
        setViewNotification(false);
      };
    }
  }, []);

  return (
    <>
      {viewNotification && (
        <Notification
          severity={"info"}
          onClose={() => setViewNotification(false)}
          message={notificationPayload?.notification?.body}
          open={viewNotification}
          autoHideDuration={60000}
          alertTitle={notificationPayload?.notification?.title}
          icon={
            <Avatar
              variant="circular"
              src={notificationPayload?.notification?.image}
              sx={{ width: 50, height: 50 }}
            />
          }
        />
      )}
    </>
  );
};

export default NotificationViewer;
