import React from "react";
import Routes from "./routes";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { red } from "@material-ui/core/colors";

const App = () => {
  //temporarily limit the theme to dark mode
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const boolTrue = true;
  const temp = boolTrue || prefersDarkMode;

  const darkTheme = React.useMemo(
    () =>
      createTheme({
        typography: {
          button: {
            textTransform: "none",
          },
        },
        palette: {
          mode: "dark",
          text: {
            primary: "#fff",
            secondary: "rgba(255,255,255,0.7)",
            disabled: "rgba(255,255,255,0.5)",
            icon: "rgba(255,255,255,0.5)",
            linkIcon: "rgba(236,79,79,0.5)",
          },
          primary: {
            main: "#353535",
          },
          secondary: red,
          background: {
            paper: "#353535",
            default: "#202124",
          },
          table: "#353535",
          tooltip: "#5b5959",
          action: {
            active: "#fff",
            selected: "#fff",
          },
        },
        components: {
          // Name of the component
          MuiLinearProgress: {
            styleOverrides: {
              // Name of the slot
              root: {
                // Some CSS
                height: "14px",
                borderRadius: "10px",
              },
              determinate: {
                backgroundColor: "#343232",
              },
              bar1Determinate: {
                borderRadius: "inherit",
                background: "#fff",
              },
            },
          },
        },
      }),
    []
  );

  const lightTheme = React.useMemo(
    () =>
      createTheme({
        typography: {
          button: {
            textTransform: "none",
          },
        },
        palette: {
          mode: "light",
          text: {
            primary: "#1a1919",
            secondary: "#262323",
            disabled: "rgba(33,31,31,0.57)",
            icon: "#1c1a1a",
            linkIcon: "rgba(233,29,29,0.5)",
          },
          secondary: red,
          primary: {
            main: "#ffffff",
          },
          background: {
            paper: "#ffffff",
            default: "#f0f2f5",
          },
          table: "#ffffff",
          tooltip: "#353535",
          action: {
            active: "#262020",
            selected: "#1f1e1e",
          },
        },
        components: {
          // Name of the component
          MuiLinearProgress: {
            styleOverrides: {
              // Name of the slot
              root: {
                // Some CSS
                height: "14px",
                borderRadius: "10px",
              },
              determinate: {
                backgroundColor: "#343232",
              },
              bar1Determinate: {
                borderRadius: "inherit",
                background: "rgba(41,36,36,0.5)",
              },
            },
          },
        },
      }),
    []
  );

  return (
    <ThemeProvider theme={temp ? darkTheme : lightTheme}>
      <CssBaseline />
      <Routes />
    </ThemeProvider>
  );
};

export default App;
