import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import SummaryCard from "./SummaryCard";
import SummaryData from "./SummaryData";
import { connect, useDispatch, useSelector } from "react-redux";
import { clearStatistics, getRepoStatistics } from "../../../store/actions";
import Skeleton from "@material-ui/core/Skeleton";
import Notification from "../../../helpers/Notification";
import { createSelectorFactory } from "../../../utils/createSelectorFactory";
import { useFirebase } from "react-redux-firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  container: {
    padding: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: theme.palette.text.primary,
  },
}));

const Statistics = ({ summary, statistics }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const [errorOpen, setErrorOpen] = useState(false);
  const loading = useSelector(
    ({
      repoData: {
        statistics: { loading },
      },
    }) => loading
  );
  const error = useSelector(
    ({
      repoData: {
        statistics: { error },
      },
    }) => error
  );
  const language = useSelector(
    ({
      repoData: {
        statistics: { language },
      },
    }) => language
  );

  const onErrorNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  useEffect(() => {
    getRepoStatistics(language)(dispatch, firebase);
  }, [dispatch, language, firebase]);

  useEffect(() => () => clearStatistics()(dispatch), [dispatch]);

  useEffect(() => {
    if (error) {
      setErrorOpen(true);
    }
  }, [error]);

  return (
    <div className={classes.root}>
      {error && (
        <Notification
          severity={"error"}
          onClose={onErrorNotificationClose}
          message={error}
          open={errorOpen}
          autoHideDuration={60000}
        />
      )}
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12}>
          {loading || !Boolean(summary) ? (
            <Skeleton
              variant="rectangular"
              height={215}
              animation="wave"
              sx={{
                borderRadius: "4px",
              }}
            />
          ) : (
            <SummaryCard summary={summary} />
          )}
        </Grid>
        <Grid item xs={12}>
          {loading || !Boolean(statistics) ? (
            <Skeleton
              variant="rectangular"
              width={400}
              height={450}
              animation="wave"
              sx={{
                borderRadius: "4px",
              }}
            />
          ) : (
            <SummaryData statistics={statistics} />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default connect((state) => ({
  summary: createSelectorFactory("repoData.statistics.summary")(state),
  statistics: createSelectorFactory("repoData.statistics.data")(state),
}))(Statistics);
