import * as actions from "./actionTypes";
import axios from "axios";
import { API_BASE } from "../../config";

export const getResultsHead =
  (repoOwner, repoName, language) => async (dispatch, firebase) => {
    try {
      dispatch(actions.getResultsHeadData.trigger());
      const token = await firebase.auth().currentUser.getIdToken();
      const response = await axios.get(
        `${API_BASE}/api/results/head/${repoOwner}/${repoName}/${language}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      dispatch(actions.getResultsHeadData.success(response?.data));
    } catch (e) {
      dispatch(
        actions.getResultsHeadData.failure(
          e?.response?.data?.message || "error occurred!"
        )
      );
    }
  };

export const clearResultsHeadData = () => (dispatch) =>
  dispatch(actions.getResultsHeadData.fulfill());

export const setCommitSha = (commit, left) => (dispatch) =>
  left
    ? dispatch(actions.setLeftCommit.success(commit))
    : dispatch(actions.setRightCommit.success(commit));

export const getCommitShaList =
  (repoOwner, repoName) => async (dispatch, firebase) => {
    try {
      dispatch(actions.getCommitsList.trigger());
      const token = await firebase.auth().currentUser.getIdToken();
      const response = await axios.get(
        `${API_BASE}/api/results/commits/${repoOwner}/${repoName}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      dispatch(actions.getCommitsList.success(response?.data?.data));
    } catch (e) {
      dispatch(
        actions.getCommitsList.failure(
          e?.response?.data?.message || "error occurred!"
        )
      );
    }
  };

export const clearCommitShaList = () => (dispatch) =>
  dispatch(actions.getCommitsList.fulfill());

export const getCommitDetails =
  (repoOwner, repoName, commit, language, left) =>
  async (dispatch, firebase) => {
    try {
      left
        ? dispatch(actions.getLeftCommitDetails.trigger())
        : dispatch(actions.getRightCommitDetails.trigger());
      const token = await firebase.auth().currentUser.getIdToken();
      const response = await axios.get(
        `${API_BASE}/api/results/details/${repoOwner}/${repoName}/${commit}/${language}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (left) {
        dispatch(actions.getLeftCommitDetails.success(response?.data));
      } else {
        dispatch(actions.getRightCommitDetails.success(response?.data));
      }
    } catch (e) {
      left
        ? dispatch(
            actions.getLeftCommitDetails.failure(
              e?.response?.data?.message || "error occurred!"
            )
          )
        : dispatch(
            actions.getRightCommitDetails.failure(
              e?.response?.data?.message || "error occurred!"
            )
          );
    }
  };

export const clearCommitDetails = (left) => (dispatch) =>
  left
    ? dispatch(actions.getLeftCommitDetails.fulfill())
    : dispatch(actions.getRightCommitDetails.fulfill());

export const getSunburstData =
  (repoOwner, repoName, commit, language, left) =>
  async (dispatch, firebase) => {
    try {
      left
        ? dispatch(actions.getLeftSunBurstData.trigger())
        : dispatch(actions.getRightSunBurstData.trigger());
      left
        ? dispatch(actions.getLeftScoresData.trigger())
        : dispatch(actions.getRightScoresData.trigger());
      const token = await firebase.auth().currentUser.getIdToken();
      const response = await axios.get(
        `${API_BASE}/api/results/sunburst/${repoOwner}/${repoName}/${commit}/${language}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (left) {
        dispatch(
          actions.getLeftSunBurstData.success(response?.data?.sunburstData)
        );
        dispatch(actions.getLeftScoresData.success(response?.data?.scores));
      } else {
        dispatch(
          actions.getRightSunBurstData.success(response?.data?.sunburstData)
        );
        dispatch(actions.getRightScoresData.success(response?.data?.scores));
      }
    } catch (e) {
      left
        ? dispatch(
            actions.getLeftSunBurstData.failure(
              e?.response?.data?.message || "error occurred!"
            )
          )
        : dispatch(
            actions.getRightSunBurstData.failure(
              e?.response?.data?.message || "error occurred!"
            )
          );
      left
        ? dispatch(
            actions.getLeftScoresData.failure(
              e?.response?.data?.message || "error occurred!"
            )
          )
        : dispatch(
            actions.getRightScoresData.failure(
              e?.response?.data?.message || "error occurred!"
            )
          );
    }
  };

export const clearSunburstData = (left) => (dispatch) =>
  left
    ? dispatch(actions.getLeftSunBurstData.fulfill())
    : dispatch(actions.getRightSunBurstData.fulfill());

export const clearScores = (left) => (dispatch) =>
  left
    ? dispatch(actions.getLeftScoresData.fulfill())
    : dispatch(actions.getRightScoresData.fulfill());

export const clearPositions = (left) => (dispatch) =>
  left
    ? dispatch(actions.setLeftCommit.fulfill())
    : dispatch(actions.setRightCommit.fulfill());

export const getVulnerabilities =
  (repoOwner, repoName, commit, path, language, left) =>
  async (dispatch, firebase) => {
    try {
      left
        ? dispatch(actions.getLeftVulnerabilities.trigger())
        : dispatch(actions.getRightVulnerabilities.trigger());
      const token = await firebase.auth().currentUser.getIdToken();
      const response = await axios.post(
        `${API_BASE}/api/results/vulnerabilities/${repoOwner}/${repoName}/${commit}/${language}`,
        { path },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      left
        ? dispatch(actions.getLeftVulnerabilities.success(response?.data?.data))
        : dispatch(
            actions.getRightVulnerabilities.success(response?.data?.data)
          );
    } catch (e) {
      left
        ? dispatch(
            actions.getLeftVulnerabilities.failure(
              e?.response?.data?.message || "error occurred!"
            )
          )
        : dispatch(
            actions.getRightVulnerabilities.failure(
              e?.response?.data?.message || "error occurred!"
            )
          );
    }
  };

export const getDependencies =
  (repoOwner, repoName, commit, path, language, left) =>
  async (dispatch, firebase) => {
    try {
      if (language !== "js") {
        return;
      }
      left
        ? dispatch(actions.getLeftDependencies.trigger())
        : dispatch(actions.getRightDependencies.trigger());
      const token = await firebase.auth().currentUser.getIdToken();
      const response = await axios.post(
        `${API_BASE}/api/results/dependencies/${repoOwner}/${repoName}/${commit}/${language}`,
        { path },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      left
        ? dispatch(actions.getLeftDependencies.success(response?.data?.data))
        : dispatch(actions.getRightDependencies.success(response?.data?.data));
    } catch (e) {
      left
        ? dispatch(
            actions.getLeftDependencies.failure(
              e?.response?.data?.message || "error occurred!"
            )
          )
        : dispatch(
            actions.getRightDependencies.failure(
              e?.response?.data?.message || "error occurred!"
            )
          );
    }
  };

export const clearVulnerabilities = (left) => (dispatch) =>
  left
    ? dispatch(actions.getLeftVulnerabilities.fulfill())
    : dispatch(actions.getRightVulnerabilities.fulfill());

export const clearDependencies = (left) => (dispatch) =>
  left
    ? dispatch(actions.getLeftDependencies.fulfill())
    : dispatch(actions.getRightDependencies.fulfill());

export const getTimelineScoresData =
  (repoOwner, repoName, language) => async (dispatch, firebase) => {
    try {
      dispatch(actions.getTimelineData.trigger());
      const token = await firebase.auth().currentUser.getIdToken();
      const response = await axios.get(
        `${API_BASE}/api/results/timeline/${repoOwner}/${repoName}/${language}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      dispatch(actions.getTimelineData.success(response?.data));
    } catch (e) {
      dispatch(
        actions.getTimelineData.failure(
          e?.response?.data?.message || "error occurred!"
        )
      );
    }
  };

export const clearTimelineScoresData = () => (dispatch) =>
  dispatch(actions.getTimelineData.fulfill());
