import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty, isLoaded } from "react-redux-firebase";
import NotificationViewer from "../../helpers/NotificationViewer";

const useStyles = makeStyles((theme) => ({
  typography: {
    flexGrow: 1,
    textAlign: "center",
  },
  linkText: {
    color: theme.palette.text.primary,
  },
}));

const Home = () => {
  const classes = useStyles();
  const auth = useSelector(({ firebase: { auth } }) => auth);

  return (
    <div className="container">
      <NotificationViewer />
      <Typography variant="h4" color="inherit" className={classes.typography}>
        Welcome to Sequza!
      </Typography>
      {isLoaded(auth) && !isEmpty(auth) ? (
        <Typography variant="h6" color="inherit" className={classes.typography}>
          Go to{" "}
          <Link className={classes.linkText} to={"/dashboard"}>
            Dashboard
          </Link>{" "}
          to continue...
        </Typography>
      ) : (
        <Typography variant="h6" color="inherit" className={classes.typography}>
          <Link className={classes.linkText} to={"/login"}>
            Login
          </Link>{" "}
          to continue...
        </Typography>
      )}
    </div>
  );
};

export default Home;
