import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import userReducer from "./userReducer";
import providerReducer from "./providerReducer";
import repoDataReducer from "./repoDataReducer";
import resultsReducer from "./resultsReducer";

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer, // <- needed if using firestore
  userData: userReducer,
  providerData: providerReducer,
  repoData: repoDataReducer,
  resultsData: resultsReducer,
});

export default rootReducer;
