import * as React from "react";
import { GridOverlay } from "@material-ui/data-grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  indeterminate: {
    background: "linear-gradient(to right,transparent,#ff1744)",
  },
  root: {
    height: "4px !important",
    borderRadius: "0px !important",
  },
}));

const CustomLoadingOverlay = () => {
  const classes = useStyles();
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress
          classes={{
            root: classes.root,
            indeterminate: classes.indeterminate,
          }}
        />
      </div>
    </GridOverlay>
  );
};

export default CustomLoadingOverlay;
