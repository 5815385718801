import * as React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/styles";
import AnalysisCardTag from "./AnalysisCardTag";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import UpdateIcon from "@material-ui/icons/Update";
import ChangeCircleIcon from "@material-ui/icons/ChangeCircle";
import CustomizedToolTip from "../../../../helpers/CustomizedToolTip";
import LinearProgressWithLabel from "../../../../helpers/LinearProgressWithLabel";
import TimeAgo from "react-timeago";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPoll } from "@fortawesome/free-solid-svg-icons/faPoll";

const AnalyzingCardTitle = ({
  repoURL,
  repoOwner,
  repoName,
  tag,
  analysisCompleted,
  classes,
  languages,
  viewOnly,
}) => (
  <div style={{ fontSize: "17px", display: "flex", alignItems: "center" }}>
    <div>
      <a
        href={repoURL}
        target="_blank"
        rel="noreferrer noopener"
        className={classes.anchor}
      >
        {repoOwner}/{repoName}
      </a>{" "}
      {tag}
    </div>
    {!viewOnly && analysisCompleted && (
      <Link
        style={{ marginLeft: "auto", marginRight: 0, textDecoration: "none" }}
        to={`/repo/${repoOwner}/${repoName}/${languages.split(",")[0]}`}
      >
        <Fab
          classes={{
            root: "fab-root",
          }}
          size="small"
          aria-label="view-results"
          color="primary"
        >
          <span className="fab-icon">
            <FontAwesomeIcon
              style={{ marginTop: "4px", fontSize: "20px" }}
              icon={faPoll}
            />
          </span>
          <span className="fab-text" />
        </Fab>
      </Link>
    )}
  </div>
);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  typography: {
    color: theme.palette.text.disabled,
  },
  button: {
    fontSize: "12px !important",
    backgroundColor: "transparent !important",
    border: "1px solid #4eaf1e !important",
    "&:hover": {
      backgroundColor: "#253023 !important",
    },
  },
  anchor: {
    wordWrap: "break-word",
    wordBreak: "break-all",
    whiteSpace: "normal",
    marginRight: "5px",
    color: "inherit",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const AnalyzingCard = ({ repoData, viewOnly }) => {
  const classes = useStyles();
  const {
    analysis_completed: analysisCompleted,
    analysis_time: analysisTime,
    commits,
    commits_count: commitsCount,
    current,
    repo_name,
    server_name: serverName,
    timestamp,
    eta,
    commitLate,
    progress,
    repo_url: repoURL,
    languages,
  } = repoData;
  const repoOwner = repo_name.split("_")[0];
  const repoName = repo_name.split(repoOwner + "_")[1];

  return (
    <Card
      elevation={3}
      sx={{
        minHeight: 430,
        minWidth: 345,
        maxWidth: 400,
        my: "auto",
        mx: "auto",
        backgroundColor: analysisCompleted
          ? "#253023"
          : commitLate
          ? "#2f2225"
          : "inherit",
        border: `1px solid ${
          analysisCompleted ? "#4eaf1e" : commitLate ? "#b22930" : "transparent"
        }`,
      }}
    >
      <CardHeader
        title={
          <AnalyzingCardTitle
            repoOwner={repoOwner}
            repoName={repoName}
            analysisCompleted={analysisCompleted}
            classes={classes}
            repoURL={repoURL}
            languages={languages}
            tag={
              <AnalysisCardTag
                analysisCompleted={analysisCompleted}
                commitLate={commitLate}
              />
            }
            viewOnly={viewOnly}
          />
        }
        sx={{ padding: "16px 24px 16px 24px" }}
      />
      <Divider />
      <CardContent>
        <List component="nav" aria-label="main mailbox folders">
          <ListItem>
            <ListItemIcon>
              <DesktopWindowsIcon />
            </ListItemIcon>
            <ListItemText primary={serverName} />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <HourglassEmptyIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography>
                  Last commit took {analysisTime} minutes{" "}
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <UpdateIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <CustomizedToolTip
                  arrow
                  placement="top"
                  title={<TimeAgo date={timestamp} />}
                >
                  <Typography noWrap>
                    Last commit analyzed <TimeAgo date={timestamp} />
                  </Typography>
                </CustomizedToolTip>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <ChangeCircleIcon />
            </ListItemIcon>
            <ListItemText
              primary={
                <CustomizedToolTip arrow placement="top" title={current}>
                  {analysisCompleted ? (
                    <Typography noWrap>Last analyzed: {current}</Typography>
                  ) : (
                    <Typography noWrap>Now analyzing: {current}</Typography>
                  )}
                </CustomizedToolTip>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <div className={classes.root}>
                  <LinearProgressWithLabel
                    value={analysisCompleted ? 100 : progress}
                    analysisCompleted={analysisCompleted}
                    commitLate={commitLate}
                  />
                </div>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <div>
                  <Typography className={classes.typography}>
                    {commits} of {commitsCount} commits analyzed.
                  </Typography>
                  <Typography
                    className={classes.typography}
                    style={{ color: analysisCompleted && "#4bb31a" }}
                  >
                    {analysisCompleted ? (
                      "Analysis completed"
                    ) : (
                      <>Completes in {<TimeAgo date={eta} />}</>
                    )}
                  </Typography>
                </div>
              }
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

export default AnalyzingCard;
