import React from "react";
import Chip from "@material-ui/core/Chip";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons/faSync";

/**
 *
 * @param analysisCompleted {boolean}
 * @param commitLate {boolean}
 * @returns {JSX.Element}
 * @constructor
 */
const AnalysisCardTag = ({ analysisCompleted, commitLate }) => {
  if (analysisCompleted) {
    return (
      <Chip
        icon={<CheckCircleIcon />}
        label="completed"
        color="secondary"
        variant="outlined"
        size="small"
        sx={{
          color: "#4ba520",
          backgroundColor: "#323f2e",
          borderColor: "#3d582b",
          borderRadius: "1px",
          padding: "5px 5px 5px 5px",
          marginRight: "5px",
        }}
      />
    );
  } else if (commitLate) {
    return (
      <Chip
        icon={<CancelIcon />}
        label="error"
        color="secondary"
        variant="outlined"
        size="small"
        sx={{
          color: "#94252b",
          backgroundColor: "#442c2f",
          borderColor: "#652c30",
          borderRadius: "1px",
          padding: "5px 5px 5px 5px",
          marginLeft: "5px",
        }}
      />
    );
  } else {
    return (
      <Chip
        icon={
          <FontAwesomeIcon
            icon={faSync}
            style={{ color: "#2169af", marginRight: "2px", fontSize: "12px" }}
            spin
          />
        }
        label="analyzing"
        sx={{
          color: "#1e76c6",
          backgroundColor: "#2d3948",
          borderColor: "#2c4f70",
          borderRadius: "1px",
          padding: "5px 5px 5px 5px",
          marginLeft: "5px",
        }}
        variant="outlined"
        size="small"
      />
    );
  }
};

export default AnalysisCardTag;
