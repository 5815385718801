import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  title: {
    flexGrow: 1,
    color: theme.palette.text.primary,
  },
  button: {
    marginTop: theme.spacing(0),
    fontSize: "15px",
    "&:hover": {
      border: "1px solid #000",
      color: "#000",
    },
  },
}));

const GitLabQueue = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.title}>
          GitLab support coming soon!
        </Typography>
      </Grid>
    </Grid>
  );
};

export default GitLabQueue;
