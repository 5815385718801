import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/core/Alert";
import AlertTitle from "@material-ui/core/AlertTitle";
import React from "react";

const Notification = ({
  open,
  autoHideDuration,
  onClose,
  elevation,
  variant,
  severity,
  message,
  alertTitle,
  ...rest
}) => (
  <Snackbar
    open={open}
    autoHideDuration={autoHideDuration || 6000}
    onClose={onClose}
  >
    <Alert
      elevation={elevation || 6}
      variant={variant || "filled"}
      onClose={onClose}
      severity={severity}
      {...rest}
    >
      {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
      {message}
    </Alert>
  </Snackbar>
);

export default Notification;
