import * as actions from "../../actions/actionTypes";

const initialState = {
  loading: false,
  summary: null,
  data: null,
  error: null,
  language: "js",
};

const statisticsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.setStatisticsLanguage.SUCCESS:
      return {
        ...state,
        language: payload,
      };

    case actions.getStatistics.TRIGGER:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case actions.getStatistics.SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        summary: payload.summary,
        data: payload.data,
      };

    case actions.getStatistics.FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case actions.getStatistics.FULFILL:
      return {
        ...state,
        loading: false,
        summary: null,
        data: null,
        error: null,
      };

    default:
      return state;
  }
};

export default statisticsReducer;
