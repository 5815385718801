import * as actions from "./actionTypes";

export const signInWithProviderID = () => async (firebase, dispatch) => {
  try {
    dispatch(actions.signInAction.trigger());
    await firebase.login({
      provider: "google",
      type: "popup",
      scopes: [""],
    });
    dispatch(actions.signInAction.success());
  } catch (e) {
    dispatch(actions.signInAction.failure(e));
  }
};

export const signOut = () => async (firebase) => {
  try {
    await firebase.logout();
    window.location.reload();
  } catch (e) {
    console.log(e.message);
  }
};

export const storeFCMKey = (FCMKey, uid) => async (firebase) => {
  try {
    await firebase
      .database()
      .ref("users")
      .child(uid)
      .child("fcmKey")
      .set(FCMKey);
  } catch (e) {
    console.log(e.message);
  }
};
