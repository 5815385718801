import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Providers from "./Providers";
import Queueing from "./Queueing";
import Analyzing from "./Analyzing";
import Statistics from "./Statistics";
import { createSelector } from "reselect";
import { get } from "lodash";
import { connect, useSelector } from "react-redux";
import CustomizedToolTip from "../../helpers/CustomizedToolTip";
import { FCM_VAPID_KEY, messaging } from "../../config";
import { useFirebase } from "react-redux-firebase";
import { storeFCMKey } from "../../store/actions";
import NotificationViewer from "../../helpers/NotificationViewer";
import { useHistory, useLocation } from "react-router-dom";
import { invert } from "lodash";

const useStyles = makeStyles((theme) => ({
  typography: {
    flexGrow: 1,
    textAlign: "center",
  },
  linkText: {
    color: theme.palette.text.primary,
  },
  appBar: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  tabPanel: {
    padding: "1px 0 0 0 !important",
  },
}));

const getPathMapping = (key, inverted = false) => {
  const mapping = {
    1: "/dashboard/providers",
    2: "/dashboard/queue",
    3: "/dashboard/analyzing",
    4: "/dashboard/statistics",
  };
  return inverted ? (invert(mapping)[key] || "1").toString() : mapping[key];
};

const Dashboard = ({ providers }) => {
  const firebase = useFirebase();
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  const [value, setValue] = useState(getPathMapping(pathname, true));

  useEffect(() => {
    setValue(getPathMapping(pathname, true));
  }, [pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history.push(getPathMapping(newValue));
  };

  const uid = useSelector(
    ({
      firebase: {
        auth: { uid },
      },
    }) => uid
  );

  useEffect(() => {
    if (messaging) {
      if (
        Notification.permission !== "granted" &&
        Notification.permission !== "denied"
      ) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            messaging
              .getToken({
                vapidKey: FCM_VAPID_KEY,
              })
              .then((refreshedToken) => {
                storeFCMKey(refreshedToken, uid)(firebase);
              })
              .catch((e) => console.log(e));
          }
        });
      }
    }
  }, [firebase, uid]);

  return (
    <div className="container">
      <NotificationViewer />
      <TabContext value={value}>
        <AppBar position="static" className={classes.appBar} color="default">
          <TabList
            indicatorColor={"secondary"}
            textColor="inherit"
            onChange={handleChange}
            aria-label="dashboard tabs"
          >
            <Tab label="Providers" value="1" />
            <Tab
              disabled={!Boolean(providers.length)}
              style={{ pointerEvents: "auto" }}
              label={
                !Boolean(providers.length) ? (
                  <CustomizedToolTip title="Queue Analysis is disabled since no providers have been authorized!">
                    <span>Queue Analysis</span>
                  </CustomizedToolTip>
                ) : (
                  "Queue Analysis"
                )
              }
              value="2"
            />
            <Tab
              label={
                !Boolean(providers.length) ? (
                  <CustomizedToolTip title="Analyzing is disabled since no providers have been authorized!">
                    <span>Analyzing</span>
                  </CustomizedToolTip>
                ) : (
                  "Analyzing"
                )
              }
              value="3"
              disabled={!Boolean(providers.length)}
              style={{ pointerEvents: "auto" }}
            />
            <Tab
              label={
                !Boolean(providers.length) ? (
                  <CustomizedToolTip title="Statistics is disabled since no providers have been authorized!">
                    <span>Statistics</span>
                  </CustomizedToolTip>
                ) : (
                  "Statistics"
                )
              }
              value="4"
              disabled={!Boolean(providers.length)}
              style={{ pointerEvents: "auto" }}
            />
          </TabList>
        </AppBar>
        <TabPanel className={classes.tabPanel} value="1">
          <Providers />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value="2">
          <Queueing />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value="3">
          <Analyzing />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value="4">
          <Statistics />
        </TabPanel>
      </TabContext>
    </div>
  );
};

const getProviders = createSelector(
  (state) => get(state, "firebase.profile.tokens", {}),
  (tokens) => Object.keys(tokens)
);

export default connect((state) => ({
  providers: getProviders(state),
}))(Dashboard);
