export const getLanguage = (language) => {
  switch (language.toLowerCase()) {
    case "javascript":
      return "js";
    case "typescript":
      return "js";
    case "c":
      return "cpp";
    case "c++":
      return "cpp";
    case "java":
      return "java";
    case "python":
      return "py";
    default:
      return "";
  }
};
