import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(() => ({
  barSuccess: {
    backgroundColor: "#4eaf1e !important",
  },
  barError: {
    backgroundColor: "#f5222d !important",
  },
  bar1Indeterminate: {
    backgroundColor: "transparent !important",
  },
  bar2Indeterminate: {
    backgroundColor: "#1d8adb !important",
  },
  indeterminate: {
    background: "linear-gradient(to right,#108ee9,#87d068)",
  },
}));

const LinearProgressWithLabel = ({
  value,
  analysisCompleted,
  commitLate,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <Box
        width="100%"
        mr={1}
        sx={{
          backgroundColor: "#675a5a",
          borderRadius: "10px",
        }}
      >
        <Box width={`${value}%`}>
          <LinearProgress
            variant={
              analysisCompleted || commitLate ? "determinate" : "indeterminate"
            }
            value={100}
            color={analysisCompleted || commitLate ? "primary" : "inherit"}
            classes={{
              bar: analysisCompleted
                ? classes.barSuccess
                : commitLate
                ? classes.barError
                : null,
              bar1Indeterminate: classes.bar1Indeterminate,
              bar2Indeterminate: classes.bar2Indeterminate,
              indeterminate: classes.indeterminate,
            }}
            {...props}
          />
        </Box>
      </Box>
      {analysisCompleted ? (
        <CheckCircleIcon sx={{ color: "#4eaf1e" }} />
      ) : (
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            value
          )}%`}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default LinearProgressWithLabel;
