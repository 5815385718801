import locationHelperBuilder from "redux-auth-wrapper/history4/locationHelper";
import { connectedRouterRedirect } from "redux-auth-wrapper/history4/redirect";
import Spinner from "../helpers/Spinner";
import { isLoaded, isEmpty } from "react-redux-firebase";
import { get } from "lodash";
import { compose } from "redux";

const locationHelper = locationHelperBuilder({});

export const UserIsAuthenticated = connectedRouterRedirect({
  wrapperDisplayName: "UserIsAuthenticated",
  AuthenticatingComponent: Spinner,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || "/login",
  authenticatingSelector: ({ firebase: { auth, isInitializing } }) =>
    !isLoaded(auth) || isInitializing === true,
  authenticatedSelector: ({ firebase: { auth } }) =>
    authenticatedSelectorForAuthenticated(auth),
});

export const UserIsNotAuthenticated = connectedRouterRedirect({
  wrapperDisplayName: "UserIsNotAuthenticated",
  AuthenticatingComponent: Spinner,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || "/dashboard",
  authenticatingSelector: ({ firebase: { auth, profile, isInitializing } }) =>
    (!isLoaded(auth) || isInitializing === true) && isLoaded(profile),
  authenticatedSelector: ({ firebase: { auth } }) =>
    authenticatedSelectorForNotAuthenticated(auth),
});

export const CheckRepoOwner = connectedRouterRedirect({
  wrapperDisplayName: "CheckRepoOwner",
  AuthenticatingComponent: Spinner,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || "/dashboard",
  authenticatingSelector: ({ firebase: { auth, isInitializing } }) =>
    !isLoaded(auth) || isInitializing === true,
  authenticatedSelector: (
    { firebase: { profile } },
    {
      match: {
        params: { owner, repo },
      },
    }
  ) => isRepoOwner(profile, `${owner}_${repo}`),
});

export const UserAllowDashboard = connectedRouterRedirect({
  wrapperDisplayName: "UserAllowDashboard",
  AuthenticatingComponent: Spinner,
  allowRedirectBack: false,
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || "/dashboard/providers",
  authenticatingSelector: ({ firebase: { auth, isInitializing } }) =>
    !isLoaded(auth) || isInitializing === true,
  authenticatedSelector: (state) =>
    authenticatedSelectorForUserAllowDashboard(
      get(state, "firebase.profile.tokens", {})
    ),
});

/**
 * @param {array<object>} profile.repositories
 * @param {object} profile
 * @param {any} repository
 * @returns {boolean}
 */
const isRepoOwner = (profile, repository) =>
  Boolean(Object.values(profile?.repositories || {}).length) &&
  Object.values(profile?.repositories || {}).includes(repository);

const authenticatedSelectorForNotAuthenticated = (auth) =>
  isLoaded(auth) && !isEmpty(auth)
    ? !(
        auth.emailVerified &&
        ["google.com"].includes(get(auth, "providerData[0].providerId", ""))
      )
    : true;

const authenticatedSelectorForAuthenticated = (auth) =>
  isLoaded(auth) &&
  !isEmpty(auth) &&
  ["google.com"].includes(get(auth, "providerData[0].providerId", ""));

const authenticatedSelectorForUserAllowDashboard = (tokens) =>
  Boolean(Object.keys(tokens).length);

export const UserIsAllowDashboard = compose(
  UserIsAuthenticated,
  UserAllowDashboard
);
