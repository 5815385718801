import createRoutine from "redux-routines";

export const signInAction = createRoutine("USER_LOGIN");
export const getGHRepoList = createRoutine("GET_GITHUB_REPO_LIST");
export const getBBRepoList = createRoutine("GET_BITBUCKET_REPO_LIST");
export const getFilteredGHRepoList = createRoutine(
  "GET_FILTERED_GITHUB_REPO_LIST"
);
export const getFilteredBBRepoList = createRoutine(
  "GET_FILTERED_BITBUCKET_REPO_LIST"
);
export const enqueueGHAnalysis = createRoutine("ENQUEUE_GITHUB_ANALYSIS");
export const enqueueBBAnalysis = createRoutine("ENQUEUE_BITBUCKET_ANALYSIS");
export const getStatistics = createRoutine("GET_STATISTICS");
export const getResultsHeadData = createRoutine("GET_RESULTS_HEAD_DATA");
export const setLeftCommit = createRoutine("SET_LEFT_COMMIT");
export const setRightCommit = createRoutine("SET_RIGHT_COMMIT");
export const getCommitsList = createRoutine("GET_COMMITS_LIST");
export const getLeftCommitDetails = createRoutine("GET_LEFT_COMMIT_DETAILS");
export const getRightCommitDetails = createRoutine("GET_RIGHT_COMMIT_DETAILS");
export const getLeftSunBurstData = createRoutine("GET_LEFT_SUNBURST_DATA");
export const getRightSunBurstData = createRoutine("GET_RIGHT_SUNBURST_DATA");
export const getLeftScoresData = createRoutine("GET_LEFT_SCORES_DATA");
export const getRightScoresData = createRoutine("GET_RIGHT_SCORES_DATA");
export const setStatisticsLanguage = createRoutine("SET_STATISTICS_LANGUAGE");
export const getLeftVulnerabilities = createRoutine("GET_LEFT_VULNERABILITIES");
export const getRightVulnerabilities = createRoutine(
  "GET_RIGHT_VULNERABILITIES"
);
export const getLeftDependencies = createRoutine("GET_LEFT_DEPENDENCIES");
export const getRightDependencies = createRoutine("GET_RIGHT_DEPENDENCIES");
export const getTimelineData = createRoutine("GET_TIMELINE_DATA");
