import React from "react";
import { makeStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Logo from "../../helpers/logo.png";
import { useDispatch } from "react-redux";
import { useFirebase } from "react-redux-firebase";
import { signInWithProviderID } from "../../store/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons/faGoogle";

const Login = () => {
  const dispatch = useDispatch();
  const firebase = useFirebase();

  const classes = makeStyles((theme) => ({
    root: {
      minWidth: 350,
    },
    title: {
      fontSize: 14,
    },
    large: {
      paddingBottom: "6px",
      marginBottom: "2px",
      filter: "drop-shadow(0px 0px 15px #222)",
    },
    typography: {
      flexGrow: 1,
      textAlign: "center",
      color: theme.palette.text.primary,
    },
    button: {
      marginTop: theme.spacing(2),
      fontSize: "18px",
      width: "150px",
      "&:hover": {
        border: "1px solid #4285F4",
        color: "#4285F4",
      },
    },
  }))();

  return (
    <div className="container">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "70vh" }}
      >
        <Grid item xs={3} style={{ textAlign: "center" }}>
          <Avatar
            variant="square"
            src={Logo}
            className={classes.large}
            sx={{ width: 100, height: 100 }}
          />
          <Typography variant="h5" className={classes.typography}>
            Sequza
          </Typography>
        </Grid>
        <Grid item xs={10} style={{ textAlign: "center", marginTop: "50px" }}>
          <Card className={classes.root}>
            <CardContent>
              <Typography
                variant="h5"
                className={classes.typography}
                style={{ marginBottom: "10px" }}
              >
                Log in with Google
              </Typography>
              <Button
                variant="outlined"
                color="inherit"
                size={"large"}
                className={classes.button}
                onClick={() => signInWithProviderID()(firebase, dispatch)}
                startIcon={<FontAwesomeIcon icon={faGoogle} size="lg" />}
              >
                Google
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
