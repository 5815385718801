import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import WarningAmberIcon from "@material-ui/icons/WarningAmber";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiDialog-paper": {
      width: "80%",
      maxHeight: 435,
      backgroundColor: "rgba(0,0,0,0.75)",
      border: "1px solid",
      borderColor: theme.palette.warning.main,
      color: theme.palette.warning.main,
    },
  },
}));

const ConfirmationDialog = (props) => {
  const classes = useStyles();
  const { onClose, open, action, message, ...other } = props;

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    window.open(action);
    onClose();
  };

  return (
    <Dialog
      className={classes.root}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      PaperProps={{ elevation: 0 }}
      {...other}
    >
      <DialogTitle
        id="confirmation-dialog-title"
        sx={{ display: "flex", gap: "3px", alignItems: "center" }}
      >
        <WarningAmberIcon fontSize="1.25rem" />
        Confirm this action!
      </DialogTitle>
      <DialogContent dividers>{message}</DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          variant="outlined"
          autoFocus
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleOk}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
