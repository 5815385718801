import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Logo from "../../helpers/logo.png";
import Avatar from "@material-ui/core/Avatar";
import { NavLink, useLocation } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuIcon from "@material-ui/icons/Menu";
import Home from "@material-ui/icons/Home";
import Dashboard from "@material-ui/icons/Dashboard";
import { useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirebase } from "react-redux-firebase";
import { signOut } from "../../store/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons/faSignInAlt";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(5),
  },
  title: {
    flexGrow: 1,
    color: theme.palette.text.primary,
  },
  large: {
    paddingTop: "3px",
    paddingBottom: "2px",
    height: theme.spacing(8),
    width: theme.spacing(8),
  },
  navDisplayFlex: {
    display: `flex`,
    justifyContent: `space-between`,
  },
  linkText: {
    textDecoration: `none`,
    color: theme.palette.text.disabled,
  },
  linkTextActive: {
    textDecoration: `none`,
    color: theme.palette.text.primary,
  },
  appBar: {
    paddingRight: "79px",
    paddingLeft: "118px",
    paddingBottom: "1px",
    borderBottom: "solid 1px transparent !important",
    boxShadow:
      "0 3px 6px -2px rgba(0, 0, 0, 0.12),0 6px 16px -11px rgba(0, 0, 0, 0.08)",
    top: 0,
    marginBottom: "20px",
    zIndex: 99990,
    "@media (max-width: 900px)": {
      paddingLeft: 0,
      paddingRight: "10px",
    },
  },
  drawerContainer: {
    padding: "20px 30px",
  },
  navItems: {
    marginLeft: "4px",
  },
  icons: {
    marginRight: "4px",
  },
}));

const ProfileMenuListItems = ({ anchorEl, handleClose, classes, firebase }) => (
  <Menu
    id="menu-appbar"
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    keepMounted
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    open={Boolean(anchorEl)}
    onClose={handleClose}
    TransitionComponent={Fade}
  >
    <MenuItem onClick={handleClose}>
      <NavLink
        to="/login"
        onClick={() => signOut()(firebase)}
        className={classes.linkTextActive}
      >
        Sign Out
      </NavLink>
    </MenuItem>
  </Menu>
);

const MenuListItems = ({ anchorEl, handleClose, classes, isActive }) => (
  <Menu
    id="menu-appbar"
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    keepMounted
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    open={Boolean(anchorEl)}
    onClose={handleClose}
    TransitionComponent={Fade}
  >
    <MenuItem onClick={handleClose}>
      <NavLink
        to={"/dashboard/providers"}
        className={classes.linkText}
        activeClassName={classes.linkTextActive}
        isActive={isActive}
      >
        Providers
      </NavLink>
    </MenuItem>
    <MenuItem onClick={handleClose}>
      <NavLink
        to={"/dashboard/queue"}
        className={classes.linkText}
        activeClassName={classes.linkTextActive}
        isActive={isActive}
      >
        Queue Analysis
      </NavLink>
    </MenuItem>
    <MenuItem onClick={handleClose}>
      <NavLink
        to={"/dashboard/analyzing"}
        className={classes.linkText}
        activeClassName={classes.linkTextActive}
        isActive={isActive}
      >
        Analyzing
      </NavLink>
    </MenuItem>
    <MenuItem onClick={handleClose}>
      <NavLink
        to={"/dashboard/statistics"}
        className={classes.linkText}
        activeClassName={classes.linkTextActive}
        isActive={isActive}
      >
        Statistics
      </NavLink>
    </MenuItem>
  </Menu>
);

const Navbar = () => {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();
  const auth = useSelector(({ firebase: { auth } }) => auth);
  const firebase = useFirebase();
  const [anchorEl, setAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { pathname } = useLocation();

  const handleMenu = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleProfileClose = () => setProfileAnchorEl(null);

  const handleDrawerOpen = () => setDrawerOpen(true);
  const handleDrawerClose = () => setDrawerOpen(false);
  const isActive = (match) => match?.isExact;
  const isMatched = pathname.includes("/dashboard");
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleProfileClick = (event) => setProfileAnchorEl(event.currentTarget);

  useEffect(() => {
    handleClose();
    handleProfileClose();
  }, [mdUp]);

  return mdUp ? (
    <div className={classes.root}>
      <AppBar position="sticky" className={classes.appBar} color="default">
        <Toolbar>
          <Avatar
            variant="square"
            src={Logo}
            className={classes.large}
            sx={{ width: 60, height: 60 }}
          />
          <Typography variant="h6" className={classes.title}>
            Sequza{" "}
            <Chip
              size="small"
              label="Beta"
              variant="outlined"
              color="secondary"
            />
          </Typography>
          <List
            component={"nav"}
            aria-labelledby={"main navigation"}
            className={classes.navDisplayFlex}
          >
            <NavLink
              to={"/"}
              className={classes.linkText}
              activeClassName={classes.linkTextActive}
              isActive={isActive}
            >
              <ListItem button>
                <Home />{" "}
                <ListItemText className={classes.navItems} primary={"Home"} />
              </ListItem>{" "}
            </NavLink>
            <NavLink
              to={"/analyzing"}
              className={classes.linkText}
              activeClassName={classes.linkTextActive}
              isActive={isActive}
            >
              <ListItem button>
                <AutorenewIcon />{" "}
                <ListItemText
                  className={classes.navItems}
                  primary={"Analyzing"}
                />
              </ListItem>{" "}
            </NavLink>
            <NavLink
              to={"/complete"}
              className={classes.linkText}
              activeClassName={classes.linkTextActive}
              isActive={isActive}
            >
              <ListItem button>
                <CheckCircleOutlineIcon />{" "}
                <ListItemText
                  className={classes.navItems}
                  primary={"Complete"}
                />
              </ListItem>{" "}
            </NavLink>
            {isLoaded(auth) && !isEmpty(auth) && (
              <div>
                <ListItem
                  button
                  onClick={handleMenu}
                  sx={{
                    color: isMatched
                      ? theme.palette.text.primary
                      : theme.palette.text.disabled,
                  }}
                >
                  <Dashboard />{" "}
                  <ListItemText
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    primary="Dashboard"
                  />
                </ListItem>
                <MenuListItems
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  classes={classes}
                  isActive={isActive}
                />
              </div>
            )}
            {isLoaded(auth) && !isEmpty(auth) ? (
              <div>
                <ListItem
                  button
                  sx={{ color: "text.disabled" }}
                  onClick={handleProfileClick}
                >
                  <AccountCircleIcon />
                  <ListItemText
                    className={classes.navItems}
                    primary={"Profile"}
                  />
                </ListItem>
                <ProfileMenuListItems
                  anchorEl={profileAnchorEl}
                  handleClose={handleProfileClose}
                  classes={classes}
                  isActive={isActive}
                  firebase={firebase}
                />
              </div>
            ) : (
              <NavLink
                to={"/login"}
                className={classes.linkText}
                activeClassName={classes.linkTextActive}
                isActive={isActive}
              >
                <ListItem button>
                  <FontAwesomeIcon icon={faSignInAlt} size="lg" />
                  <ListItemText
                    className={classes.navItems}
                    primary={"Login"}
                  />
                </ListItem>
              </NavLink>
            )}
          </List>
        </Toolbar>
      </AppBar>
    </div>
  ) : (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Avatar
            variant="square"
            src={Logo}
            style={{
              paddingBottom: "6px",
            }}
            className={classes.large}
          />
          <Typography variant="h6" className={classes.title}>
            Sequza
          </Typography>
          <List
            component={"nav"}
            aria-labelledby={"main navigation"}
            className={classes.navDisplayFlex}
          >
            <IconButton
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon />
            </IconButton>

            <Drawer
              {...{
                anchor: "right",
                open: drawerOpen,
                onClose: handleDrawerClose,
              }}
            >
              <div className={classes.drawerContainer}>
                <NavLink
                  to={"/"}
                  className={classes.linkText}
                  activeClassName={classes.linkTextActive}
                  isActive={isActive}
                >
                  <MenuItem>
                    <Home className={classes.icons} /> Home
                  </MenuItem>
                </NavLink>
                <NavLink
                  to={"/analyzing"}
                  className={classes.linkText}
                  activeClassName={classes.linkTextActive}
                  isActive={isActive}
                >
                  <MenuItem>
                    <Home className={classes.icons} /> Analyzing
                  </MenuItem>
                </NavLink>
                <NavLink
                  to={"/complete"}
                  className={classes.linkText}
                  activeClassName={classes.linkTextActive}
                  isActive={isActive}
                >
                  <MenuItem>
                    <Home className={classes.icons} /> Complete
                  </MenuItem>
                </NavLink>
                {isLoaded(auth) && !isEmpty(auth) && (
                  <div>
                    <MenuItem
                      onClick={handleClick}
                      id="fade-button"
                      aria-controls="fade-menu"
                      aria-haspopup="true"
                      aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                      sx={{
                        color: isMatched
                          ? theme.palette.text.primary
                          : theme.palette.text.disabled,
                      }}
                    >
                      <Dashboard className={classes.icons} /> Dashboard
                    </MenuItem>
                    <MenuListItems
                      anchorEl={anchorEl}
                      handleClose={handleClose}
                      classes={classes}
                      isActive={isActive}
                    />
                  </div>
                )}
                {isLoaded(auth) && !isEmpty(auth) ? (
                  <div>
                    <MenuItem
                      sx={{ color: "text.disabled" }}
                      onClick={handleProfileClick}
                    >
                      <AccountCircleIcon className={classes.icons} /> Profile
                    </MenuItem>
                    <ProfileMenuListItems
                      anchorEl={profileAnchorEl}
                      handleClose={handleProfileClose}
                      classes={classes}
                      isActive={isActive}
                      firebase={firebase}
                    />
                  </div>
                ) : (
                  <NavLink
                    to={"/login"}
                    className={classes.linkText}
                    activeClassName={classes.linkTextActive}
                    isActive={isActive}
                  >
                    <MenuItem>
                      <FontAwesomeIcon
                        className={classes.icons}
                        icon={faSignInAlt}
                        size="lg"
                      />{" "}
                      Login
                    </MenuItem>
                  </NavLink>
                )}
              </div>
            </Drawer>
          </List>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
