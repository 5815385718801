import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import AnalyzingCard from "./AnalyzingCard";
import { getEstimatedTime, hhmmssToSecs } from "../../../utils/timeConverter";
import { connect, useSelector } from "react-redux";
import { useFirebaseConnect } from "react-redux-firebase";
import Skeleton from "@material-ui/core/Skeleton";
import { createSelectorFactory } from "../../../utils/createSelectorFactory";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  container: {
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1,
    color: theme.palette.text.primary,
  },
}));

const Analyzing = ({ userProjects }) => {
  const [projects, setProjects] = useState(null);
  const classes = useStyles();
  const uid = useSelector(
    ({
      firebase: {
        auth: { uid },
      },
    }) => uid
  );
  useFirebaseConnect([{ path: `/user_projects/${uid}` }]);

  useEffect(() => {
    if (userProjects?.[uid] === null) {
      setProjects([]);
    } else if (userProjects?.[uid]) {
      /**
       * @param repoData.commits {number} count of the commits which are analyzed
       * @param repoData.repo_name {string} repository name
       * @param repoData.commits_count {number} total commits count of the repository
       * @param repoData.analysis_completed {boolean} whether the analysis is still running
       */
      const repoDataArray = userProjects[uid].map(({ value: repoData }) => {
        if (!repoData?.repo_name) {
          return null;
        }
        repoData["commits"] = repoData?.commits || 0;
        repoData["eta"] = getEstimatedTime(
          repoData?.commits || 0,
          repoData?.commits_count || 1,
          repoData?.analysis_time || "0:00:00"
        );
        repoData["analysis_time"] = repoData?.analysis_time
          ? repoData.analysis_time.split(".")[0]
          : "0:00:00";
        repoData["commitLate"] =
          new Date() - (repoData?.timestamp || new Date()) >
          300000 + hhmmssToSecs(repoData?.analysis_time || "0:00:00") * 1000;
        repoData["progress"] = repoData.analysis_completed
          ? 100
          : Math.round(
              ((repoData?.commits || 0) / (repoData?.commits_count || 1)) * 100
            );
        return repoData;
      });
      setProjects(repoDataArray.filter(Boolean));
    }
  }, [userProjects, uid]);

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={12} sx={{ marginLeft: "10px" }}>
          {Boolean(projects) ? (
            <Typography variant="body1" className={classes.title}>
              {Boolean(projects.length)
                ? "Currently analyzing repositories"
                : "No currently analyzing repositories"}
            </Typography>
          ) : (
            <Skeleton variant="text" animation="wave" sx={{ width: "400px" }} />
          )}
        </Grid>
        {Boolean(projects) ? (
          <>
            {Boolean(projects.length) && (
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  {projects.map((repoData, index) => (
                    <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
                      <AnalyzingCard repoData={repoData} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
          </>
        ) : (
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <Skeleton
                  variant="rectangular"
                  width={345}
                  height={430}
                  animation="wave"
                  sx={{
                    borderRadius: "4px",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default connect((state) => ({
  userProjects: createSelectorFactory("firebase.ordered.user_projects")(state),
}))(Analyzing);
