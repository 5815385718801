import React, { useState } from "react";
import { faBitbucket } from "@fortawesome/free-brands-svg-icons/faBitbucket";
import { faGithub } from "@fortawesome/free-brands-svg-icons/faGithub";
import { faGitlab } from "@fortawesome/free-brands-svg-icons/faGitlab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TabContext from "@material-ui/lab/TabContext";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import GitHubProvider from "./GitHubProvider";
import BitbucketProvider from "./BitbucketProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  title: {
    flexGrow: 1,
    color: theme.palette.text.primary,
  },
  button: {
    marginTop: theme.spacing(0),
    fontSize: "15px",
    "&:hover": {
      border: "1px solid #000",
      color: "#000",
    },
  },
  tabPanel: {
    flexShrink: 10,
    width: "100%",
  },
}));

const Providers = () => {
  const classes = useStyles();
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <TabContext value={value}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Providers List"
          className={classes.tabs}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#1460d4",
            },
          }}
          textColor="inherit"
        >
          <Tab
            icon={<FontAwesomeIcon icon={faGithub} size="2x" />}
            label="GitHub"
            value="1"
          />
          <Tab
            icon={<FontAwesomeIcon icon={faBitbucket} size="2x" />}
            label="Bitbucket"
            value="3"
          />
          <Tab
            icon={<FontAwesomeIcon icon={faGitlab} size="2x" />}
            label="GitLab"
            value="2"
          />
        </Tabs>
        <TabPanel className={classes.tabPanel} value="1" align="center">
          <GitHubProvider />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value="2">
          <Typography variant="body1" className={classes.title}>
            GitLab support coming soon!
          </Typography>
        </TabPanel>
        <TabPanel className={classes.tabPanel} value="3" align="center">
          <BitbucketProvider />
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default Providers;
