import React from "react";
import Grid from "@material-ui/core/Grid";
import SummaryDataCard from "./SummaryDataCard";

const SummaryData = ({ statistics }) => {
  return (
    <Grid container spacing={3}>
      {statistics.map((data, index) => (
        <Grid item xs={12} md={3} lg={3} xl={3} key={index}>
          <SummaryDataCard summaryData={data} />
        </Grid>
      ))}
    </Grid>
  );
};

export default SummaryData;
