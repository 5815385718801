import React from "react";
import Divider from "@material-ui/core/Divider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import Typography from "@material-ui/core/Typography";

const renderTree = (nodes) => (
  <TreeItem
    key={nodes.id}
    nodeId={nodes.id}
    label={nodes.name}
    onClick={() => Boolean(nodes?.url) && window.open(nodes.url)}
  >
    {Array.isArray(nodes.children)
      ? nodes.children.map((node) => renderTree(node))
      : null}
  </TreeItem>
);

const Dependents = ({ dependencies }) => {
  return (
    <div>
      <Divider sx={{ mt: 2, mb: 2 }} textAlign="left">
        Dependents
      </Divider>
      {Boolean(Object.keys(dependencies).length) ? (
        <TreeView
          aria-label="rich object"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{ flexGrow: 1, overflowY: "auto", mb: 2 }}
        >
          {renderTree(dependencies)}
        </TreeView>
      ) : (
        <Typography variant="body2" sx={{ mb: 2 }}>
          No dependency data
        </Typography>
      )}
    </div>
  );
};

export default Dependents;
