import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Legend from "./Legend";
import Alert from "@material-ui/core/Alert";
import AlertTitle from "@material-ui/core/AlertTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Collapse from "@material-ui/core/Collapse";
import { get } from "lodash";
import colors from "../../../../utils/colors.json";
import { getLanguage } from "../../../../utils/getLanguage";
import Chip from "@material-ui/core/Chip";
import { setStatLanguage } from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";

const typographyBody = {
  marginLeft: "5px",
  marginBottom: "3px",
  marginRight: "5px",
};

const SummaryCard = ({ summary, removeLanguagesBar }) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = React.useState(true);

  const language = useSelector(
    ({
      repoData: {
        statistics: { language },
      },
    }) => language
  );

  const {
    repos,
    intro,
    fix,
    introFix,
    introFixPresent,
    introOnly,
    fixOnly,
    commits,
  } = summary;

  return (
    <div>
      {!Boolean(repos) && (
        <Collapse in={open}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            variant="outlined"
            severity="info"
            sx={{ m: 3 }}
          >
            <AlertTitle>Pro-tip!</AlertTitle>Currently you don't have any
            repository analyzed. Head over to Queue Analysis to analyze a
            repository.
          </Alert>
        </Collapse>
      )}
      <Card elevation={3}>
        <Legend open={visible} onClose={() => setVisible(false)} />
        <CardHeader
          title={
            <div
              style={{
                display: "flex",
                alignContent: "space-between",
              }}
            >
              <Typography
                variant="h6"
                sx={{ display: "flex", alignItems: "center" }}
              >
                Summary{" "}
                <InfoOutlinedIcon
                  sx={{ mx: 0.5, cursor: "pointer" }}
                  onClick={() => setVisible(true)}
                />
              </Typography>
              <div
                style={{
                  alignItems: "center",
                  marginLeft: "auto",
                  marginRight: 0,
                }}
              >
                {!removeLanguagesBar &&
                  ["JavaScript", "Java", "Python", "C++"].map((lang, index) => (
                    <Chip
                      key={index}
                      clickable
                      onClick={() =>
                        setStatLanguage(getLanguage(lang))(dispatch)
                      }
                      label={lang}
                      color="primary"
                      variant="outlined"
                      size="small"
                      sx={{
                        color: get(
                          colors,
                          `${lang.toLowerCase()}.color`,
                          "inherit"
                        ),
                        backgroundColor:
                          language === getLanguage(lang)
                            ? "#222324"
                            : "transparent",
                        borderColor: get(
                          colors,
                          `${lang.toLowerCase()}.color`,
                          "inherit"
                        ),
                        borderRadius: "1px",
                        padding: "5px 5px 5px 5px",
                        marginLeft: "10px",
                        fontSize: "12px",
                      }}
                    />
                  ))}
              </div>
            </div>
          }
          sx={{ padding: "16px 24px 16px 24px" }}
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              xl={3}
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="h6">
                {repos.toLocaleString()} repositories
              </Typography>
              <Typography variant="body2" sx={typographyBody}>
                analyzed
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              xl={3}
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="h6">
                {intro.toLocaleString()} Intro
              </Typography>
              <Typography variant="body2" sx={typographyBody}>
                commits
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              xl={3}
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="h6">
                {introOnly.toLocaleString()} Intro Only
              </Typography>
              <Typography variant="body2" sx={typographyBody}>
                commits
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              xl={3}
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="h6">{fix.toLocaleString()} Fix</Typography>
              <Typography variant="body2" sx={typographyBody}>
                commits
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              xl={3}
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="h6">
                {fixOnly.toLocaleString()} Fix Only
              </Typography>
              <Typography variant="body2" sx={typographyBody}>
                commits
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              xl={3}
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="h6">
                {introFix.toLocaleString()} Intro+Fix
              </Typography>
              <Typography variant="body2" sx={typographyBody}>
                commits
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              xl={3}
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="h6">
                {commits.toLocaleString()} total
              </Typography>
              <Typography variant="body2" sx={typographyBody}>
                commits
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              xl={3}
              sx={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="body2" sx={typographyBody}>
                Intro+Fix found in
              </Typography>
              <Typography variant="h6">
                {introFixPresent.toLocaleString()} of {repos.toLocaleString()} (
                {Boolean(repos)
                  ? Math.ceil((introFixPresent / repos) * 100)
                  : "N/A"}
                %)
              </Typography>
              <Typography variant="body2" sx={typographyBody}>
                repositories
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default SummaryCard;
