import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardHeader from "@material-ui/core/CardHeader";
import Divider from "@material-ui/core/Divider";
import { useSelector } from "react-redux";
import Notification from "../../../helpers/Notification";
import Skeleton from "@material-ui/core/Skeleton";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/core/Alert";
import AlertTitle from "@material-ui/core/AlertTitle";
import { get } from "lodash";
import ReactMarkdown from "react-markdown";
import { makeStyles } from "@material-ui/styles";
import Fab from "@material-ui/core/Fab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGitAlt } from "@fortawesome/free-brands-svg-icons/faGitAlt";

const useStyles = makeStyles((theme) => ({
  anchor: {
    wordWrap: "break-word",
    wordBreak: "break-all",
    whiteSpace: "normal",
    marginRight: "5px",
    color: "inherit",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  link: {
    wordWrap: "break-word",
    wordBreak: "break-all",
    whiteSpace: "normal",
    color: `${theme.palette.text.primary} !important`,
    fontSize: theme.typography.body2.fontSize,
    textDecoration: "none",
    marginLeft: "auto",
    marginRight: 0,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  markdown: {
    "& >p": {
      margin: 0,
      fontSize: theme.typography.body2.fontSize,
    },
  },
}));

/**
 *
 * @param {boolean} leftSide
 * @param {object} commitDetails
 * @param {number} commitDetails.introFixCommit
 * @param {string} commitDetails.commit
 * @param {string} commitDetails.date
 * @param {string} commitDetails.author
 * @param {string} commitDetails.message
 * @param {string} commitDetails.commitURL
 * @param {string} commitDetails.filesURL
 * @param {string} commitDetails.authorURL
 * @returns {JSX.Element}
 * @constructor
 */
const CommitDetailsCard = ({ left: leftSide, commitDetails }) => {
  const classes = useStyles();
  const [errorOpen, setErrorOpen] = useState(false);

  const error = useSelector(({ resultsData: { details } }) =>
    get(details, leftSide ? "left.error" : "right.error", null)
  );
  const loading = useSelector(({ resultsData: { details } }) =>
    get(details, leftSide ? "left.loading" : "right.loading", false)
  );

  const onErrorNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  useEffect(() => {
    if (error) {
      setErrorOpen(true);
    }
  }, [error]);

  return (
    <div>
      {error && (
        <Notification
          severity={"error"}
          onClose={onErrorNotificationClose}
          message={error}
          open={errorOpen}
          autoHideDuration={60000}
        />
      )}
      {loading || !Boolean(commitDetails) ? (
        <Skeleton variant="rectangular" height={225} animation="wave" />
      ) : (
        <Card
          elevation={0}
          sx={{ borderRadius: "0 !important", minHeight: "270px" }}
        >
          <CardHeader
            title={
              <div
                style={{
                  fontSize: "17px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div>
                  <a
                    href={commitDetails?.commitURL}
                    target="_blank"
                    rel="noreferrer noopener"
                    className={classes.anchor}
                  >
                    {commitDetails?.commit}
                  </a>
                </div>

                <Link
                  style={{
                    marginLeft: "auto",
                    marginRight: 0,
                    textDecoration: "none",
                  }}
                  target="_blank"
                  rel="noreferrer noopener"
                  href={commitDetails?.filesURL}
                >
                  <Fab
                    classes={{
                      root: "fab-root",
                    }}
                    size="small"
                    aria-label="view-files"
                    color="primary"
                  >
                    <span className="fab-icon">
                      <FontAwesomeIcon
                        size="2x"
                        style={{ marginTop: "3px" }}
                        icon={faGitAlt}
                      />
                    </span>
                    <span className="fab-view-files" />
                  </Fab>
                </Link>
              </div>
            }
            sx={{ padding: "16px 24px 16px 24px" }}
          />
          <Divider />
          <CardContent sx={{ padding: "0 !important" }}>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem sx={{ pb: 0 }}>
                <ListItemText
                  primary={
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <Typography variant="body2">Commit Date:</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="body2">
                          {commitDetails?.date}
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                />
              </ListItem>
              <ListItem sx={{ pb: 0 }}>
                <ListItemText
                  primary={
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <Typography variant="body2">Commit Author:</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Link
                          className={classes.link}
                          target="_blank"
                          rel="noreferrer noopener"
                          href={commitDetails?.authorURL}
                        >
                          {commitDetails?.author}
                        </Link>
                      </Grid>
                    </Grid>
                  }
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={
                    <Grid container spacing={1}>
                      <Grid item xs={3}>
                        <Typography variant="body2">Commit Message:</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <ReactMarkdown
                          className={classes.markdown}
                          children={commitDetails?.message}
                        />
                      </Grid>
                    </Grid>
                  }
                />
              </ListItem>
            </List>
          </CardContent>
          {Boolean(commitDetails?.introFixCommit) && (
            <div>
              <Divider />
              <CardContent>
                <Alert severity="warning">
                  <AlertTitle>Intro+Fix Commit</AlertTitle>
                  This commit has introduced new vulnerabilities while fixing
                  one or more existing ones.
                  <Divider sx={{ mb: 1, mt: 1 }} />
                  Tip - Sort the score breakdown table by the column "Intro+Fix"
                  in descending order to find out which files contain the new
                  vulnerabilities.
                </Alert>
              </CardContent>
            </div>
          )}
        </Card>
      )}
    </div>
  );
};

export default CommitDetailsCard;
