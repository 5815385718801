import React, { useState } from "react";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Chip from "@material-ui/core/Chip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCodeBranch } from "@fortawesome/free-solid-svg-icons/faCodeBranch";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/styles";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import { useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import { getCWENoLink } from "../../../../utils/getCWENoLink";

const TablePaginationActions = ({ count, page, rowsPerPage, onPageChange }) => {
  const theme = useTheme();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

const HeaderColumns = () =>
  [
    "Vulnerability",
    "CWE",
    "Start Line",
    "Start Column",
    "End Line",
    "End Column",
    "View File",
  ].map((value, index) => <TableCell key={index}>{value}</TableCell>);

/**
 * @param {array<object>} rows
 * @param {string} rows.vulnerability
 * @param {string} rows.id
 * @param {array<string>} rows.cwe
 * @param {number} rows.startLine
 * @param {number} rows.startColumn
 * @param {number} rows.endLine
 * @param {number} rows.new
 * @param {number} rows.endColumn
 * @param {string} rows.url
 * @param {string} rows.vulnerabilityURL
 * @param classes
 * @constructor
 */
const Rows = ({ rows, classes }) =>
  rows.map((row) => (
    <TableRow
      key={row.id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        <a
          href={row.vulnerabilityURL}
          className="repository-cell"
          target="_blank"
          rel="noreferrer noopener"
        >
          {row.vulnerability}
        </a>{" "}
        {Boolean(row.new) && (
          <Tooltip title="This vulnerability has occurred for the first time">
            <Chip
              variant="outlined"
              size="small"
              label="New"
              color="secondary"
            />
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        <Grid container direction="row" alignItems="center" spacing={1}>
          {getCWENoLink(row.cwe).map((item, index) => (
            <Grid item key={index}>
              <Chip
                clickable
                component="a"
                target="_blank"
                href={item.link}
                rel="noopener noreferrer"
                size="small"
                label={item.cweNo}
                variant="outlined"
                color="default"
              />
            </Grid>
          ))}
        </Grid>
      </TableCell>
      <TableCell>{row.startLine}</TableCell>
      <TableCell>{row.startColumn}</TableCell>
      <TableCell>{row.endLine}</TableCell>
      <TableCell>{row.endColumn}</TableCell>
      <TableCell>
        <Link
          href={row.url}
          target="_blank"
          rel="noreferrer noopener"
          color="inherit"
        >
          <FontAwesomeIcon icon={faCodeBranch} className={classes.faIcon} />
        </Link>
      </TableCell>
    </TableRow>
  ));

const useStyles = makeStyles((theme) => ({
  faIcon: {
    marginLeft: "10px",
    fontSize: "20px",
    "&:hover": {
      color: theme.palette.text.linkIcon,
    },
  },
}));

const Vulnerabilities = ({ vulnerabilities }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - vulnerabilities.length)
      : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <Divider sx={{ mt: 2 }} textAlign="left">
        Vulnerabilities Detected
      </Divider>
      <Box component="div" sx={{ mt: 2 }}>
        <TableContainer component={Paper} elevation={1}>
          <Table sx={{ minWidth: 650, maxHeight: 0 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <HeaderColumns />
              </TableRow>
            </TableHead>
            <TableBody>
              <Rows
                rows={
                  rowsPerPage > 0
                    ? vulnerabilities.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : vulnerabilities
                }
                classes={classes}
              />
              {emptyRows > 0 && (
                <TableRow style={{ height: 65 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={7}
                  count={vulnerabilities.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
};

export default Vulnerabilities;
