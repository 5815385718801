import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/core/Autocomplete";
import Divider from "@material-ui/core/Divider";
import { setCommitSha } from "../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Notification from "../../../helpers/Notification";
import Skeleton from "@material-ui/core/Skeleton";
import CommitCardHeader from "./CommitCardHeader";

const CommitCard = ({ left, commitList }) => {
  const dispatch = useDispatch();
  const [commit, setCommit] = useState(null);
  const [errorOpen, setErrorOpen] = useState(false);

  const error = useSelector(
    ({
      resultsData: {
        commits: {
          list: { error },
        },
      },
    }) => error
  );
  const loading = useSelector(
    ({
      resultsData: {
        commits: {
          list: { loading },
        },
      },
    }) => loading
  );

  const onErrorNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  const handleOnChange = (event, newCommit) => {
    setCommitSha(newCommit?.commit || null, left)(dispatch);
    setCommit(newCommit);
  };

  useEffect(() => {
    if (error) {
      setErrorOpen(true);
    }
  }, [error]);

  return (
    <div>
      {error && (
        <Notification
          severity={"error"}
          onClose={onErrorNotificationClose}
          message={error}
          open={errorOpen}
          autoHideDuration={60000}
        />
      )}
      {loading || !Boolean(commitList) ? (
        <Skeleton variant="rectangular" height={180} animation="wave" />
      ) : (
        <Card elevation={0} sx={{ borderRadius: "0 !important" }}>
          <CardHeader
            title={<CommitCardHeader left={left} />}
            sx={{ padding: "16px 24px 16px 24px" }}
          />
          <Divider />
          <CardContent sx={{ padding: "0 !important" }}>
            <Autocomplete
              size="small"
              sx={{ padding: "24px" }}
              value={commit}
              onChange={handleOnChange}
              options={commitList}
              getOptionLabel={({ index, commit: commit_ }) =>
                `#${index} - ${commit_}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select a commit"
                  variant="outlined"
                  margin="dense"
                />
              )}
            />
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default CommitCard;
