import React, { useCallback, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import { DataGrid } from "@material-ui/data-grid";
import Chip from "@material-ui/core/Chip";
import CustomNoRowsOverlay from "../../../../helpers/CustomNoRowsOverlay";
import CustomLoadingOverlay from "../../../../helpers/CustomLoadingOverlay";
import LoadingButton from "@material-ui/lab/LoadingButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons/faPlayCircle";
import CustomizedToolTip from "../../../../helpers/CustomizedToolTip";
import {
  clearGHProvider,
  enqueueGHAnalysis,
  getFilteredGHData,
  getPaginatedGHData,
} from "../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { SUPPORTED_LANGUAGES } from "../../../../config";
import Notification from "../../../../helpers/Notification";
import Skeleton from "@material-ui/core/Skeleton";
import { useFirebase } from "react-redux-firebase";
import { find } from "lodash";

const useStyles = makeStyles((theme) => ({
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  title: {
    flexGrow: 1,
    color: theme.palette.text.primary,
  },
  button: {
    marginTop: theme.spacing(0),
    fontSize: "15px",
    "&:hover": {
      border: "1px solid #9eacea",
      color: "#9eacea",
    },
  },
  paper: {
    display: "flex",
    height: "100%",
    width: "100%",
    backgroundColor: theme.palette.table,
  },
}));

const getRepositoryCell = (params) => {
  return (
    <a
      href={params.row.url}
      className="repository-cell"
      target="_blank"
      rel="noreferrer noopener"
    >
      {params.row.repoOwner}/{params.row.repoName}
    </a>
  );
};

const getLanguageCell = (params) => {
  return params
    .getValue(params.id, "languages")
    .map((language, index) => (
      <Chip
        key={index}
        size="small"
        label={language}
        variant="outlined"
        color="default"
        style={{ marginLeft: "10px" }}
      />
    ));
};

const columns = [
  {
    field: "repository",
    headerName: "Repository",
    description: "Name of the repository",
    flex: 1,
    renderCell: getRepositoryCell,
    sortComparator: (v1, v2) => v1?.toString().localeCompare(v2?.toString()),
    filterable: false, //TODO: Remove this once the API endpoint is implemented
  },
  {
    field: "languages",
    headerName: "Language",
    description: "Main language of the repository",
    flex: 1,
    renderCell: getLanguageCell,
    sortComparator: (v1, v2) => v1?.toString().localeCompare(v2?.toString()),
    filterable: false,
  },
];

const GitHubQueue = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const firebase = useFirebase();

  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPerPage] = useState(5);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectionModel, setSelectionModel] = useState([]);
  const [errorOpen, setErrorOpen] = useState(false);
  const [enqueueErrorOpen, setEnqueueErrorOpen] = useState(false);
  const [enqueueSuccessOpen, setEnqueueSuccessOpen] = useState(false);
  const [filterValue, setFilterValue] = useState();

  const loading = useSelector(
    ({
      providerData: {
        ghData: { loading },
      },
    }) => loading
  );
  const error = useSelector(
    ({
      providerData: {
        ghData: { error },
      },
    }) => error
  );
  const dataFromGH = useSelector(
    ({
      providerData: {
        ghData: { data },
      },
    }) => data
  );

  const enqueueLoading = useSelector(
    ({
      providerData: {
        ghData: {
          enqueue: { loading },
        },
      },
    }) => loading
  );
  const enqueueError = useSelector(
    ({
      providerData: {
        ghData: {
          enqueue: { error },
        },
      },
    }) => error
  );

  const onFilterChange = useCallback((model) => {
    setFilterValue(model?.items[0].value);
  }, []);

  const handlePageChange = (page) => {
    setSelectionModel([]);
    setSelectedRow(null);
    setPage(page);
  };

  const handlePageSizeChange = (pageSize) => {
    setSelectionModel([]);
    setSelectedRow(null);
    setPerPage(pageSize);
  };

  const selectionModelChangeHandler = (model) => {
    const newSelectionModel = model;

    if (newSelectionModel.length > 1) {
      const selectionSet = new Set(selectionModel);
      const result = newSelectionModel.filter((s) => !selectionSet.has(s));
      setSelectionModel(result.length > 1 ? result.slice(0, 0) : result);
    } else if (newSelectionModel.length === 1) {
      setSelectionModel(newSelectionModel);
    } else {
      setSelectedRow(null);
      setSelectionModel(newSelectionModel);
    }
  };

  useEffect(() => {
    if (selectionModel.length === 1) {
      const selectedRowData = find(rows, ["id", selectionModel[0]]);
      setSelectedRow(selectedRowData);
    }
  }, [rows, selectionModel]);

  const onErrorNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  const onEnqueueErrorNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setEnqueueErrorOpen(false);
  };

  const onSuccessNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setEnqueueSuccessOpen(false);
  };

  const allowRowSelectable = ({ row }) =>
    Boolean(row?.languages) &&
    SUPPORTED_LANGUAGES.some((r) => row.languages.includes(r));

  useEffect(() => {
    setRows(dataFromGH?.data || []);
    setTotalCount(dataFromGH?.totalCount || 0);
  }, [dataFromGH]);

  useEffect(() => {
    if (error) {
      setErrorOpen(true);
    }
    if (enqueueError) {
      setEnqueueErrorOpen(true);
    }
    if (enqueueError === false && enqueueLoading === false) {
      setEnqueueSuccessOpen(true);
    }
  }, [error, enqueueError, enqueueLoading]);

  useEffect(() => {
    if (filterValue) {
      getFilteredGHData(filterValue)(dispatch);
    } else {
      getPaginatedGHData(page, pageSize)(dispatch, firebase);
    }
  }, [filterValue, dispatch, page, pageSize, firebase]);

  useEffect(() => {
    return () => clearGHProvider()(dispatch);
  }, [dispatch]);

  return (
    <Grid container spacing={3}>
      {error && (
        <Notification
          severity={"error"}
          onClose={onErrorNotificationClose}
          message={error}
          open={errorOpen}
        />
      )}
      {enqueueError && (
        <Notification
          severity={"error"}
          onClose={onEnqueueErrorNotificationClose}
          message={enqueueError}
          open={enqueueErrorOpen}
        />
      )}
      <Notification
        severity={"success"}
        onClose={onSuccessNotificationClose}
        message={"Successfully enqueued the repository for analysis!"}
        open={enqueueSuccessOpen}
      />
      <Grid item xs={12}>
        <Typography variant="body1" className={classes.title}>
          Repositories hosted on GitHub
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <div style={{ flexGrow: 1 }}>
            {dataFromGH?.data ? (
              <DataGrid
                rows={rows}
                columns={columns}
                filterMode="server"
                onFilterModelChange={onFilterChange}
                page={page}
                pageSize={pageSize}
                rowsPerPageOptions={[1, 5, 10, 20]}
                loading={loading}
                rowCount={totalCount}
                paginationMode="server"
                onPageChange={handlePageChange}
                onPageSizeChange={handlePageSizeChange}
                autoHeight
                pagination
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                  NoResultsOverlay: CustomNoRowsOverlay,
                  LoadingOverlay: CustomLoadingOverlay,
                }}
                checkboxSelection
                selectionModel={selectionModel}
                onSelectionModelChange={selectionModelChangeHandler}
                isRowSelectable={allowRowSelectable}
              />
            ) : (
              <Skeleton
                variant="rectangular"
                height={214}
                animation="wave"
                sx={{
                  borderRadius: "4px",
                }}
              />
            )}
          </div>
        </Paper>
      </Grid>
      {selectedRow && (
        <Grid
          container
          spacing={3}
          style={{ marginTop: "10px" }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <Typography variant="body1" className={classes.title}>
              Your selected repository is:{" "}
              <a
                href={selectedRow.url}
                className="repository-cell"
                target="_blank"
                rel="noreferrer noopener"
              >
                {selectedRow.repoOwner}/{selectedRow.repoName}
              </a>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <CustomizedToolTip
              placement="top"
              title={`Queue ${selectedRow.repoOwner}/${selectedRow.repoName} for analyzing`}
            >
              <LoadingButton
                loading={enqueueLoading}
                loadingPosition="start"
                className={classes.button}
                color="inherit"
                size={"small"}
                startIcon={<FontAwesomeIcon icon={faPlayCircle} size="2x" />}
                variant="outlined"
                onClick={() =>
                  enqueueGHAnalysis(
                    selectedRow.repoOwner,
                    selectedRow.repoName
                  )(dispatch, firebase)
                }
              >
                Enqueue for Analysis
              </LoadingButton>
            </CustomizedToolTip>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default GitHubQueue;
