import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { API_BASE } from "../../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons/faGithub";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { get } from "lodash";
import { createSelectorFactory } from "../../../../utils/createSelectorFactory";
import ConfirmationDialog from "../../../../helpers/ConfirmationDialog";

const useStyles = makeStyles((theme) => ({
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  title: {
    flexGrow: 1,
    color: theme.palette.text.primary,
  },
  button: {
    marginTop: theme.spacing(0),
    fontSize: "15px",
    "&:hover": {
      border: "1px solid #000",
      color: "#000",
    },
  },
}));

const GitHubProvider = ({ ghTokenSet, uid }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemoveAuthOnClick = () => {
    setOpen(true);
  };

  return (
    <Grid container spacing={3}>
      {ghTokenSet ? (
        <>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.title}>
              You have authorized your GitHub account. Head over to Queue
              Analysis to queue a repository for analysis.
            </Typography>
          </Grid>
          {uid && (
            <Grid item xs={12}>
              <ConfirmationDialog
                id="bitbucket-remove-auth"
                keepMounted
                open={open}
                onClose={handleClose}
                message={
                  "You are going to remove the authorization for GitHub. This action will suspend any ongoing analysis tasks related to GitHub repositories."
                }
                action={`${API_BASE}/api/github/revoke?uid=${uid}`}
              />
              <Button
                color="inherit"
                variant="outlined"
                size={"large"}
                className={classes.button}
                startIcon={<FontAwesomeIcon icon={faGithub} size="2x" />}
                onClick={handleRemoveAuthOnClick}
              >
                Remove GitHub Account
              </Button>
            </Grid>
          )}
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.title}>
              You can connect your GitHub account with Sequza to view all of
              your private and public repositories to queue them for analysis.
            </Typography>
          </Grid>
          {uid && (
            <Grid item xs={12}>
              <Button
                color="inherit"
                href={`${API_BASE}/api/github/authorize?uid=${uid}`}
                target="_blank"
                rel="noopener noreferrer"
                variant="outlined"
                size={"large"}
                className={classes.button}
                startIcon={<FontAwesomeIcon icon={faGithub} size="2x" />}
              >
                Authorize GitHub
              </Button>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

const isGHTokenSet = createSelector(
  (state) => get(state, "firebase.profile.tokens", {}),
  (tokens) => get(tokens, "github", false)
);

export default connect((state) => ({
  ghTokenSet: isGHTokenSet(state),
  uid: createSelectorFactory("firebase.auth.uid")(state),
}))(GitHubProvider);
