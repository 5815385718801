import { createStore, applyMiddleware, compose } from "redux";
import { getFirebase } from "react-redux-firebase";
import { createFirestoreInstance, getFirestore } from "redux-firestore";
import { get } from "lodash";
import logger from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import firebase from "../config";

// react-redux-firebase config
const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: false,
  attachAuthIsReady: true,
  useFirestoreForStorageMeta: false,
  profileFactory: (userData, profileData, firebase) => {
    return {
      email: get(userData, "email", ""),
      uid: get(userData, "uid", ""),
      displayName: get(userData, "displayName", ""),
      photoURL: get(userData, "photoURL", ""),
      createdAt: firebase.database.ServerValue.TIMESTAMP,
      updatedAt: firebase.database.ServerValue.TIMESTAMP,
    };
  },
};

// Create store with reducers and initial state
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialState = {};
const store = createStore(
  rootReducer,
  initialState,
  composeEnhancers(
    applyMiddleware(
      thunk.withExtraArgument({ getFirebase, getFirestore }),
      logger
    )
  )
);

export const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance, // <- needed if using firestore
};

export default store;
