import * as actions from "./actionTypes";
import axios from "axios";
import { API_BASE } from "../../config";

export const getRepoStatistics = (language) => async (dispatch, firebase) => {
  try {
    dispatch(actions.getStatistics.trigger());
    const token = await firebase.auth().currentUser.getIdToken();
    const response = await axios.get(`${API_BASE}/api/statistics/${language}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch(
      actions.getStatistics.success({
        summary: response?.data?.summary,
        data: response?.data?.data,
      })
    );
  } catch (e) {
    dispatch(
      actions.getStatistics.failure(
        e?.response?.data?.message || "error occurred!"
      )
    );
  }
};

export const clearStatistics = () => (dispatch) =>
  dispatch(actions.getStatistics.fulfill());

export const setStatLanguage = (language) => (dispatch) =>
  dispatch(actions.setStatisticsLanguage.success(language));
