import React from "react";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import CardContent from "@material-ui/core/CardContent";
import List from "@material-ui/core/List";
import Link from "@material-ui/core/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHashtag } from "@fortawesome/free-solid-svg-icons/faHashtag";
import { faCodeBranch } from "@fortawesome/free-solid-svg-icons/faCodeBranch";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  faIcon: {
    marginLeft: "10px",
    fontSize: "20px",
    "&:hover": {
      color: theme.palette.text.linkIcon,
    },
  },
}));

const SummaryDataCard = ({ summaryData }) => {
  const classes = useStyles();

  const {
    repoOwner,
    repoName,
    repoURL,
    allIntro,
    introOnly,
    allFix,
    fixOnly,
    introFix,
    commitsCount,
  } = summaryData;

  return (
    <Card elevation={3}>
      <CardHeader
        title={
          <Typography
            variant="body1"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {repoOwner}/{repoName}{" "}
            <Link
              href={repoURL}
              target="_blank"
              rel="noreferrer noopener"
              color="inherit"
            >
              <FontAwesomeIcon icon={faCodeBranch} className={classes.faIcon} />
            </Link>
          </Typography>
        }
        sx={{ padding: "16px 24px 16px 24px" }}
      />
      <Divider />
      <CardContent>
        <List component="nav" aria-label="main mailbox folders">
          <ListItem>
            <ListItemText primary="Total Intro commits" />
            <ListItemText
              primary={allIntro.toLocaleString()}
              sx={{ textAlign: "right" }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Intro only commits"
              sx={{ marginLeft: "16px" }}
            />
            <ListItemText
              primary={introOnly.toLocaleString()}
              sx={{ textAlign: "right" }}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Total Fix commits" />
            <ListItemText
              primary={allFix.toLocaleString()}
              sx={{ textAlign: "right" }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Fix only commits"
              sx={{ marginLeft: "16px" }}
            />
            <ListItemText
              primary={fixOnly.toLocaleString()}
              sx={{ textAlign: "right" }}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="Intro + Fix commits" />
            <ListItemText
              primary={introFix.toLocaleString()}
              sx={{ textAlign: "right" }}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography>
                  <FontAwesomeIcon
                    icon={faHashtag}
                    style={{ marginRight: "5px" }}
                  />
                  Total Commits
                </Typography>
              }
            />
            <ListItemText
              primary={commitsCount.toLocaleString()}
              sx={{ textAlign: "right" }}
            />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

export default SummaryDataCard;
