import { combineReducers } from "redux";
import resultsHeadReducer from "./resultsHeadReducer";
import commitsReducer from "./commitsReducer";
import detailsReducer from "./detailsReducer";
import sunburstReducer from "./sunburstReducer";
import scoresReducer from "./scoresReducer";
import vulnerabilitiesReducer from "./vulnerabilitiesReducer";
import dependenciesReducer from "./dependenciesReducer";
import timelineReducer from "./timelineReducer";

export default combineReducers({
  headData: resultsHeadReducer,
  timeline: timelineReducer,
  commits: commitsReducer,
  details: detailsReducer,
  sunburst: sunburstReducer,
  scores: scoresReducer,
  vulnerabilities: vulnerabilitiesReducer,
  dependencies: dependenciesReducer,
});
