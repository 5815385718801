import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { connect, useDispatch, useSelector } from "react-redux";
import Vulnerabilities from "./Vulnerabilities";
import Dependents from "./Dependents";
import Divider from "@material-ui/core/Divider";
import { useParams } from "react-router-dom";
import { createSelectorFactory } from "../../../../utils/createSelectorFactory";
import Skeleton from "@material-ui/core/Skeleton";
import {
  clearDependencies,
  clearVulnerabilities,
  getDependencies,
  getVulnerabilities,
} from "../../../../store/actions";
import { useFirebase } from "react-redux-firebase";
import { get } from "lodash";
import Notification from "../../../../helpers/Notification";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: `${theme.palette.background.default} !important`,
  },
  typography: {
    marginBottom: "15px",
  },
}));

const FileDetails = ({
  left: leftSide,
  open,
  onClose,
  path,
  vulnerabilities,
  dependencies,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const theme = useTheme();
  const [errorOpen, setErrorOpen] = useState(false);
  const [error, setError] = useState(null);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { owner: repoOwner, repo: repoName, language } = useParams();

  const leftCommit = useSelector(
    ({
      resultsData: {
        commits: {
          positions: { left },
        },
      },
    }) => left
  );
  const rightCommit = useSelector(
    ({
      resultsData: {
        commits: {
          positions: { right },
        },
      },
    }) => right
  );

  const vulnerabilitiesError = useSelector(
    ({ resultsData: { vulnerabilities } }) =>
      get(vulnerabilities, leftSide ? "left.error" : "right.error", null)
  );
  const vulnerabilitiesLoading = useSelector(
    ({ resultsData: { vulnerabilities } }) =>
      get(vulnerabilities, leftSide ? "left.loading" : "right.loading", false)
  );

  const dependenciesError = useSelector(({ resultsData: { dependencies } }) =>
    get(dependencies, leftSide ? "left.error" : "right.error", null)
  );
  const dependenciesLoading = useSelector(({ resultsData: { dependencies } }) =>
    get(dependencies, leftSide ? "left.loading" : "right.loading", false)
  );

  const onErrorNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  useEffect(() => {
    if (vulnerabilitiesError) {
      setErrorOpen(true);
      setError(vulnerabilitiesError);
    }
    if (dependenciesError) {
      setErrorOpen(true);
      setError(dependenciesError);
    }
  }, [vulnerabilitiesError, dependenciesError]);

  useEffect(() => {
    getVulnerabilities(
      repoOwner,
      repoName,
      leftSide ? leftCommit : rightCommit,
      path,
      language,
      leftSide
    )(dispatch, firebase);
  }, [
    repoOwner,
    repoName,
    leftSide,
    leftCommit,
    rightCommit,
    path,
    language,
    dispatch,
    firebase,
  ]);

  useEffect(() => {
    getDependencies(
      repoOwner,
      repoName,
      leftSide ? leftCommit : rightCommit,
      path,
      language,
      leftSide
    )(dispatch, firebase);
  }, [
    repoOwner,
    repoName,
    leftSide,
    leftCommit,
    rightCommit,
    language,
    dispatch,
    firebase,
    path,
  ]);

  useEffect(
    () => () => {
      clearVulnerabilities(leftSide)(dispatch);
      clearDependencies(leftSide)(dispatch);
    },
    [leftSide, dispatch]
  );

  return (
    <div>
      {error && (
        <Notification
          severity={"error"}
          onClose={onErrorNotificationClose}
          message={error}
          open={errorOpen}
          autoHideDuration={60000}
        />
      )}
      <Dialog
        keepMounted={false}
        scroll="body"
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography variant="body1" component="div">
            {path}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {Boolean(vulnerabilities) && !vulnerabilitiesLoading ? (
            <Vulnerabilities vulnerabilities={vulnerabilities} />
          ) : (
            <Skeleton variant="rectangular" height={400} animation="wave" />
          )}
        </DialogContent>
        {language === "js" && (
          <DialogContent>
            {Boolean(dependencies) && !dependenciesLoading ? (
              <Dependents dependencies={dependencies} />
            ) : (
              <Skeleton variant="rectangular" height={60} animation="wave" />
            )}
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
};

export default connect((state, { left }) => ({
  vulnerabilities: createSelectorFactory(
    `resultsData.vulnerabilities.${left ? "left" : "right"}.data`
  )(state),
  dependencies: createSelectorFactory(
    `resultsData.dependencies.${left ? "left" : "right"}.data`
  )(state),
}))(FileDetails);
