import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";
import Spinner from "./helpers/Spinner";
import Home from "./components/Home";
import { Container } from "@material-ui/core";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import {
  CheckRepoOwner,
  UserIsAllowDashboard,
  UserIsAuthenticated,
  UserIsNotAuthenticated,
} from "./auth";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Results from "./components/Results";
import NotFound from "./components/NotFound";
import Complete from "./components/Complete";
import Analyzing from "./components/Analyzing";

const AuthIsLoaded = ({ children }) => {
  const profile = useSelector(({ firebase: { profile } }) => profile);

  if (isLoaded(profile) && isEmpty(profile)) return children;

  if (isLoaded(profile) && !isEmpty(profile)) return children;

  return <Spinner />;
};

const Routes = () => {
  return (
    <Router>
      <AuthIsLoaded>
        <Navbar />
        <Container maxWidth={"xl"}>
          <Switch>
            <Route exact path={"/"} component={Home} />
            <Route exact path={"/analyzing"} component={Analyzing} />
            <Route exact path={"/complete"} component={Complete} />
            <Route
              exact
              path={"/login"}
              component={UserIsNotAuthenticated(Login)}
            />
            <Route
              exact
              path={"/dashboard"}
              component={UserIsAuthenticated(Dashboard)}
            />
            <Route
              exact
              path={"/dashboard/providers"}
              component={UserIsAuthenticated(Dashboard)}
            />
            <Route
              exact
              path={"/dashboard/queue"}
              component={UserIsAllowDashboard(Dashboard)}
            />
            <Route
              exact
              path={"/dashboard/analyzing"}
              component={UserIsAllowDashboard(Dashboard)}
            />
            <Route
              exact
              path={"/dashboard/statistics"}
              component={UserIsAllowDashboard(Dashboard)}
            />
            <Route
              exact
              path={"/repo/:owner/:repo/:language"}
              component={CheckRepoOwner(Results)}
            />
            <Route exact path={"*"} component={NotFound} />
          </Switch>
        </Container>
        <Footer />
      </AuthIsLoaded>
    </Router>
  );
};

export default Routes;
