import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import NotificationViewer from "../../helpers/NotificationViewer";
import Grid from "@material-ui/core/Grid";
import Skeleton from "@material-ui/core/Skeleton";
import SummaryCard from "../Dashboard/Statistics/SummaryCard";
import SummaryData from "../Dashboard/Statistics/SummaryData";
import { useFirebaseConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  typography: {
    flexGrow: 1,
    textAlign: "center",
  },
  linkText: {
    color: theme.palette.text.primary,
  },
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  container: {
    padding: theme.spacing(2),
  },
}));

const Complete = () => {
  useFirebaseConnect([{ path: "/statistics" }]);
  const classes = useStyles();
  const [summary, setSummary] = useState({
    repos: 0,
    intro: 0,
    fix: 0,
    introFix: 0,
    introFixPresent: 0,
    introOnly: 0,
    fixOnly: 0,
    commits: 0,
    language: "js",
  });
  const [statistics, setStatistics] = useState([]);

  const data = useSelector(({ firebase }) => firebase.ordered);

  useEffect(() => {
    let stat = [];
    let summ = {
      repos: 0,
      intro: 0,
      fix: 0,
      introFix: 0,
      introFixPresent: 0,
      introOnly: 0,
      fixOnly: 0,
      commits: 0,
      language: "js",
    };
    /**
     * @param {{}} d.value.all_intro
     * @param {{}} d.value.all_fix
     * @param {{}} d.value.intro_only
     * @param {{}} d.value.fix_only
     * @param {{}} d.value.intro_fix
     */
    data["statistics"] &&
      data["statistics"].forEach((d, i) => {
        let repoOwner = d.value.repo_name.split("_")[0];
        let repoName = d.value.repo_name.split(repoOwner + "_")[1];
        stat.push({
          key: i,
          repoOwner: repoOwner,
          repoName: repoName,
          repoURL: `https://github.com/${repoOwner}/${repoName}`,
          allIntro: d.value.all_intro,
          introOnly: d.value.intro_only,
          allFix: d.value.all_fix,
          fixOnly: d.value.fix_only,
          introFix: d.value.intro_fix,
          commitsCount: d.value.commits_count,
        });
        summ = {
          repos: summ["repos"] + 1,
          intro: summ["intro"] + d.value.all_intro,
          fix: summ["fix"] + d.value.all_fix,
          introFix: summ["introFix"] + d.value.intro_fix,
          introFixPresent:
            d.value.intro_fix > 0
              ? summ["introFixPresent"] + 1
              : summ["introFixPresent"],
          introOnly: summ["introOnly"] + d.value.intro_only,
          fixOnly: summ["fixOnly"] + d.value.fix_only,
          commits: summ["commits"] + d.value.commits_count,
          language: "js",
        };
      });
    setStatistics(stat);
    setSummary(summ);
  }, [data]);

  return (
    <div className="container">
      <NotificationViewer />
      <div className={classes.root}>
        <Grid container spacing={3} className={classes.container}>
          <Grid item xs={12}>
            {!Boolean(statistics.length) ? (
              <Skeleton
                variant="rectangular"
                height={215}
                animation="wave"
                sx={{
                  borderRadius: "4px",
                }}
              />
            ) : (
              <SummaryCard summary={summary} removeLanguagesBar />
            )}
          </Grid>
          <Grid item xs={12}>
            {!Boolean(statistics.length) ? (
              <Skeleton
                variant="rectangular"
                width={400}
                height={450}
                animation="wave"
                sx={{
                  borderRadius: "4px",
                }}
              />
            ) : (
              <SummaryData statistics={statistics} />
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Complete;
