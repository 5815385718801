import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBitbucket } from "@fortawesome/free-brands-svg-icons/faBitbucket";
import { faGithub } from "@fortawesome/free-brands-svg-icons/faGithub";
import { faGitlab } from "@fortawesome/free-brands-svg-icons/faGitlab";
import GitHubQueue from "./GitHubQueue";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import { get } from "lodash";
import GitLabQueue from "./GitLabQueue";
import BitBucketQueue from "./BitBucketQueue";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    alignContent: "center",
  },
  title: {
    flexGrow: 1,
    color: theme.palette.text.primary,
  },
  tabPanel: {
    flexShrink: 10,
    width: "100%",
  },
}));

const Queueing = ({ providers }) => {
  const classes = useStyles();
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (Boolean(providers.length) && providers.length === 1) {
      setValue(getTabsData(providers[0]).value);
    }
  }, [providers]);

  const getTabsData = (provider) => {
    switch (provider) {
      case "github":
        return {
          icon: faGithub,
          label: "GitHub",
          value: "1",
          tabPanel: <GitHubQueue />,
        };
      case "gitlab":
        return {
          icon: faGitlab,
          label: "GitLab",
          value: "2",
          tabPanel: <GitLabQueue />,
        };
      case "bitbucket":
        return {
          icon: faBitbucket,
          label: "Bitbucket",
          value: "3",
          tabPanel: <BitBucketQueue />,
        };
      default:
        return {
          icon: faGithub,
          label: "GitHub",
          value: "1",
          tabPanel: <GitHubQueue />,
        };
    }
  };

  return (
    <div className={classes.root}>
      {providers.length > 0 ? (
        <TabContext value={value}>
          <Tabs
            orientation="vertical"
            variant="standard"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
            TabIndicatorProps={{
              style: {
                backgroundColor: "#1460d4",
              },
            }}
            textColor="inherit"
          >
            {providers.map((provider, index) => (
              <Tab
                style={{ order: provider === "github" ? 0 : 1 }}
                key={index}
                icon={
                  <FontAwesomeIcon
                    icon={getTabsData(provider).icon}
                    size="2x"
                  />
                }
                label={getTabsData(provider).label}
                value={getTabsData(provider).value}
              />
            ))}
          </Tabs>
          {providers.map((provider, index) => (
            <TabPanel
              className={classes.tabPanel}
              key={index}
              value={getTabsData(provider).value}
            >
              {getTabsData(provider).tabPanel}
            </TabPanel>
          ))}
        </TabContext>
      ) : (
        <></>
      )}
    </div>
  );
};

const getProviders = createSelector(
  (state) => get(state, "firebase.profile.tokens", {}),
  (tokens) => Object.keys(tokens)
);

export default connect((state) => ({
  providers: getProviders(state),
}))(Queueing);
