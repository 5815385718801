import * as actions from "./actionTypes";
import axios from "axios";
import { API_BASE } from "../../config";

export const getPaginatedGHData =
  (page = 0, pageSize = 5) =>
  async (dispatch, firebase) => {
    try {
      dispatch(actions.getGHRepoList.trigger());
      const token = await firebase.auth().currentUser.getIdToken();
      const response = await axios.get(
        `${API_BASE}/api/github/list?page=${page}&page_size=${pageSize}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      dispatch(actions.getGHRepoList.success(response.data));
    } catch (e) {
      dispatch(
        actions.getGHRepoList.failure(
          e?.response?.data?.message || "error occurred!"
        )
      );
    }
  };

export const getPaginatedBBData =
  (pageSize = 5, url = null) =>
  async (dispatch, firebase) => {
    try {
      dispatch(actions.getBBRepoList.trigger());
      const token = await firebase.auth().currentUser.getIdToken();
      const response = await axios.post(
        `${API_BASE}/api/bitbucket/list`,
        Boolean(url) ? { page_size: pageSize, url } : { page_size: pageSize },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      dispatch(actions.getBBRepoList.success(response.data));
    } catch (e) {
      dispatch(
        actions.getBBRepoList.failure(
          e?.response?.data?.message || "error occurred!"
        )
      );
    }
  };

export const getFilteredGHData = (filteredValue) => (dispatch) => {
  try {
    dispatch(actions.getFilteredGHRepoList.trigger());
    let data = {};
    if (!filteredValue) {
      data = {
        page: 0,
        pageSize: 5,
        totalCount: 100,
        data: getMockGHData(0, 5),
      };
    } else {
      data = {
        page: 0,
        pageSize: 5,
        totalCount: 1,
        data: [
          {
            id: 1,
            repoOwner: "Niweera",
            repoName: filteredValue,
            url: `https://github.com/Niweera/${filteredValue}`,
            languages: ["JavaScript"],
          },
        ],
      };
    }
    setTimeout(() => {
      dispatch(actions.getFilteredGHRepoList.success(data));
    }, 1000);
  } catch (e) {
    dispatch(actions.getFilteredGHRepoList.failure(e));
  }
};

const getMockGHData = (page, pageSize) => {
  return [...Array(pageSize).keys()].map((number) => ({
    id: page * pageSize + number + 1,
    repoOwner: "Niweera",
    repoName: `exslt${page * pageSize + number + 1}`,
    url: `https://github.com/Niweera/exslt${page * pageSize + number + 1}`,
    languages: [
      "JavaScript",
      "Python",
      "Java",
      "C",
      "C++",
      "TypeScript",
      "Go",
      "Dart",
      "Rust",
      "Scala",
    ]
      .sort(() => Math.random() - Math.random())
      .slice(0, Math.floor((Math.random() * 10000) % 10)),
  }));
};

export const enqueueGHAnalysis =
  (repoOwner, repoName) => async (dispatch, firebase) => {
    try {
      dispatch(actions.enqueueGHAnalysis.trigger());
      const token = await firebase.auth().currentUser.getIdToken();
      await axios.get(
        `${API_BASE}/api/github/enqueue/${repoOwner}/${repoName}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      dispatch(actions.enqueueGHAnalysis.success());
    } catch (e) {
      dispatch(
        actions.enqueueGHAnalysis.failure(
          e?.response?.data?.message || "error occurred!"
        )
      );
    }
  };

export const enqueueBBAnalysis =
  (repoOwner, repoName) => async (dispatch, firebase) => {
    try {
      dispatch(actions.enqueueBBAnalysis.trigger());
      const token = await firebase.auth().currentUser.getIdToken();
      await axios.get(
        `${API_BASE}/api/bitbucket/enqueue/${repoOwner}/${repoName}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      dispatch(actions.enqueueBBAnalysis.success());
    } catch (e) {
      dispatch(
        actions.enqueueBBAnalysis.failure(
          e?.response?.data?.message || "error occurred!"
        )
      );
    }
  };

export const clearGHProvider = () => (dispatch) =>
  dispatch(actions.getGHRepoList.fulfill());

export const clearBBProvider = () => (dispatch) =>
  dispatch(actions.getBBRepoList.fulfill());
