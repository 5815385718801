import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database"; // <- needed if using firebase rtdb
import "firebase/firestore"; // <- needed if using firestore
import "firebase/functions";
import "firebase/analytics";
import "firebase/performance";
import "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCb0DIdplhlao4ibKzBbLrQ_vdasEooNnc",
  authDomain: "sequza.io",
  databaseURL: "https://analysispipeline-dev-default-rtdb.firebaseio.com",
  projectId: "analysispipeline-dev",
  storageBucket: "analysispipeline-dev.appspot.com",
  messagingSenderId: "226130020170",
  appId: "1:226130020170:web:67a30bfd880f13a08623b3",
  measurementId: "G-K12WK2S4PV",
};

// Initialize firebase instance
firebase.initializeApp(firebaseConfig);

// Initialize other services on firebase instance
firebase.firestore(); // <- needed if using firestore

export const functions = firebase.functions();
export const analytics = firebase.analytics();
export const performance = firebase.performance();

analytics.setAnalyticsCollectionEnabled(true);
performance.dataCollectionEnabled = true;
performance.instrumentationEnabled = true;

let firebaseMessaging;
if (firebase.messaging.isSupported()) {
  firebaseMessaging = firebase.messaging();
}
export const messaging = firebaseMessaging;
export const FCM_VAPID_KEY =
  "BI8z9Oxsmnr9pugsIUgKV9-w0QIQO-6_c13GBWu46ZqfM8edyJoDSd83WSnILCIranIngA0QmjQmLwtZGTMF61w";
export const API_BASE = "https://apiv2.sequza.io";
export const SUPPORTED_LANGUAGES = [
  "JavaScript",
  "Java",
  "Python",
  "C",
  "C++",
  "TypeScript",
];

export default firebase;
